import React from "react"
import {BrowserRouter as Router} from "react-router-dom";
import Menu from "../Menu";
import Routes from "../Routes";

class AddComponent extends React.Component {
    constructor(props) {
        super(props);
        this.routes = props.routes
    }

    render() {
        return (
                    <div>
                        <Menu routes={this.routes}></Menu>
                    </div>
        )
    }
}

export default AddComponent;
