import ReduxViewTableComponent from "./redux.view.table.component";
import {connect} from "react-redux";
import {loadData} from "../actions/emergency_funds"
import {withTranslation} from "react-i18next";

class EmergencyFundDependentGoalsTableComponent extends ReduxViewTableComponent {
    constructor(props) {
        const columns = [
            {
                type: 'string',
                title: props.t('Type'),
                field: 'Type',
                total: false,
                sort: true,
            },
            {
                type: 'string',
                title: props.t('Title'),
                field: 'Title',
                total: false,
                sort: true,
            },
            {
                type: 'string',
                title: props.t('Description'),
                field: 'Description',
                total: false,
                sort: true,
            },
        ]
        super({...props, columns});
    }
}

const mapStateToProps = state => {
    let dd = []
    if (state.emergency_funds.selected !== undefined && state.emergency_funds.selected !== null ) {
        const selected = state.emergency_funds.selected
        for (const account of state.accounts.entities) {
            if (account.EmergencyFundID === selected) {
                dd.push({...account, Type: 'Account'})
            }
        }
        for (const credit of state.credits.entities) {
            if (credit.EmergencyFundID === selected) {
                dd.push({...credit, Type: 'Credit'})
            }
        }
        for (const ft of state.finite_targets.entities) {
            if (ft.EmergencyFundID === selected) {
                dd.push({...ft, Type: 'Finite Target'})
            }
        }
        for (const me of state.monthly_expenses.entities) {
            if (me.EmergencyFundID === selected) {
                dd.push({...me, Type: 'Monthly Expense'})
            }
        }
        for (const rf of state.refillable_funds.entities) {
            if (rf.EmergencyFundID === selected) {
                dd.push({...rf, Type: 'Refillable Fund'})
            }
        }
    }
    return {
    data: dd,
    error: state.emergency_funds.error,
    isFetching: state.emergency_funds.loading,
}};

const mapDispatchToProps = dispatch => ({
    onReloadNeeded: () => dispatch(loadData()),
});

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(EmergencyFundDependentGoalsTableComponent));
