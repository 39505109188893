import createDataSlice from "./data.slice.creater";

const versionSlice = new createDataSlice({
    sliceName: 'version',
    baseUrl: 'version',
    loadOnAuth: true,
});

const {slice} = versionSlice;
const {reducer} = slice;
export const {loadData} = versionSlice;

export default reducer;