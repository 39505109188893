import createDataSlice from "./data.slice.creater";

const statesPerMonthSlice = new createDataSlice({
    sliceName: 'states_per_month',
    baseUrl: 'states_per_month',
});

const {slice} = statesPerMonthSlice;
const {actions, reducer} = slice;
export const {loadData} = statesPerMonthSlice;

export default reducer;