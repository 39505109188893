import createDataSlice from "./data.slice.creater";

const recordTypesSlice = new createDataSlice({
    sliceName: 'record_types',
    baseUrl: 'catalog/record_types',
    loadOnAuth: true,
});

const {slice} = recordTypesSlice;
const {actions, reducer} = slice;
export const {loadData} = recordTypesSlice;

export default reducer;