import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {addMessage} from "./messages";
import AsyncFetchData from "./fetchData";

/*export const refreshToken = createAsyncThunk(
    'auth/refresh/result',
    async (_, thunkAPI) => {
        try {
            console.log("(refresh) Starting token refresh process")
            const creds = thunkAPI.getState().auth.creds
            const response = await AsyncFetchData({token: null, loggedIn: false}, 'post', 'signin', null, null, null, creds)
            let authData = null
            console.log('(refresh) Auth reply data: ', response)
            if (response != null && response !== undefined && "jwt" in response) {
                console.log('(refresh) Auth token: ' + response.jwt)
                authData = response.jwt
                thunkAPI.dispatch(addMessage('success', 'Token refreshed successfully'))
            } else {
                console.log("(refresh) Jwt key not found auth in reply")
                thunkAPI.dispatch(addMessage('danger', 'Error on login'))
            }
            return {
                token: authData != null ? authData : null,
                loggedIn: authData != null,
                error: null,
                creds: creds,
            }
        } catch (err) {
            console.log("(refresh) Error of token refresh", err)
            thunkAPI.dispatch(addMessage('danger', 'Error on token refresh'))
            return {
                token: null,
                loggedIn: false,
                error: err,
                creds: null,
            }
        }
    }
)*/

export const login = createAsyncThunk(
    'auth/login/result',
    async (creds, thunkAPI) => {
        try {
            const response = await AsyncFetchData(thunkAPI.getState().auth,'post', 'signin', null, null, null, creds)
            let authData = null
            console.log('Auth reply data: ', response)
            if (response != null && response !== undefined && "jwt" in response) {
                console.log('Auth token: ' + response.jwt)
                authData = response.jwt
                thunkAPI.dispatch(addMessage('success', 'Login successfully'))
            } else {
                console.log("Jwt key not found auth in reply")
                thunkAPI.dispatch(addMessage('danger', 'Error on login'))
            }
            return {
                token: authData != null ? authData : null,
                loggedIn: authData != null,
                error: null,
                creds: creds,
            }
        } catch (err) {
            thunkAPI.dispatch(addMessage('danger', 'Error on login'))
            return {
                token: null,
                loggedIn: false,
                error: err,
                creds: null,
            }
        }
    }
)

export const signup = createAsyncThunk(
    'auth/signup/result',
    async (creds, thunkAPI) => {
        console.log("SignUp creds: ", creds)
        try {
            const response = await AsyncFetchData(thunkAPI.getState().auth,'post', 'signup', null, null, null, creds)
            let authData = null
            console.log('Auth reply data: ', response)
            if (response != null && response !== undefined && "jwt" in response) {
                console.log('Auth token: ' + response.jwt)
                authData = response.jwt
                thunkAPI.dispatch(addMessage('success', 'Signup & login successfully'))
            } else {
                console.log("Jwt key not found auth in reply")
                thunkAPI.dispatch(addMessage('danger', 'Error on signup'))
            }
            return {
                token: authData != null ? authData : null,
                loggedIn: authData != null,
                error: null,
                creds: creds,
            }
        } catch (err) {
            thunkAPI.dispatch(addMessage('danger', 'Error on signup'))
            return {
                token: null,
                loggedIn: false,
                error: err,
                creds: null,
            }
        }
    }
)

export const getCaptcha = createAsyncThunk(
    'auth/get_captcha/result',
    async (creds, thunkAPI) => {
        try {
            const response = await AsyncFetchData(thunkAPI.getState().auth,'get', 'captcha/new', null, null, null, null)
            let captchaData = null
            console.log('Captcha reply data: ', response)
            if (response != null && response !== undefined && "data" in response && "ID" in response.data && "ImageURL" in response.data) {
                console.log('Captcha data: ', response.data.ID, response.data.ImageURL)
                captchaData = {id: response.data.ID, url: response.data.ImageURL}
            } else {
                console.log("Captcha data not found auth in reply")
                thunkAPI.dispatch(addMessage('danger', 'Captcha data fetch error'))
            }
            return {
                captcha: captchaData,
            }
        } catch (err) {
            thunkAPI.dispatch(addMessage('danger', 'Captcha data fetch error'))
            return {
                captcha: null,
            }
        }
    }
)

const slice = createSlice({
    name: 'auth',
    initialState: {
        token: null,
        loggedIn: false,
        error: null,
        captcha: {
          id: null,
          url: null,
        },
        creds: null,
    },
    reducers: {
        logout(state, action) {
            state.token = null;
            state.loggedIn = false;
            state.error = null;
            state.creds = null;
        },
        refreshToken(state, action) {
          state.token = action.payload.token;
          state.loggedIn = action.payload.loggedIn;
          state.error = action.payload.error;
          state.creds = action.payload.creds;
        }
    },
    extraReducers: {
        [login.fulfilled]: (state, action) => {
            state.token = action.payload.token;
            state.loggedIn = action.payload.loggedIn;
            state.error = action.payload.error;
            state.creds = action.payload.creds;
        },
/*        [refreshToken.fulfilled]: (state, action) => {
            state.token = action.payload.token;
            state.loggedIn = action.payload.loggedIn;
            state.error = action.payload.error;
            state.creds = action.payload.creds;
        },*/
        [signup.fulfilled]: (state, action) => {
            state.token = action.payload.token;
            state.loggedIn = action.payload.loggedIn;
            state.error = action.payload.error;
            state.creds = action.payload.creds;
        },
        [getCaptcha.fulfilled]: (state, action) => {
            state.captcha = action.payload.captcha
        },
    },
})

const {actions, reducer} = slice;
export const {logout, refreshToken} = actions;

export default reducer;
