import createDataSlice from "./data.slice.creater";

const expensesSlice = new createDataSlice({
    sliceName: 'expenses',
    baseUrl: 'expenses',
//    updatable: true,
//    deletable: true,
//    addable: true,
    reloadOnDateChanged: true,
});

const {slice} = expensesSlice;
const {actions, reducer} = slice;
export const {select} = actions;
export const {addRow, deleteRow, loadData, updateRow} = expensesSlice;

export default reducer;