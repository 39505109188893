import createDataSlice from "./data.slice.creater";

const retirementFundsSlice = new createDataSlice({
    sliceName: 'retirement_funds',
    baseUrl: 'retirement_funds',
    updatable: true,
    deletable: true,
    addable: true,
    loadOnAuth: true,
});

const {slice} = retirementFundsSlice;
const {actions, reducer} = slice;
export const {select} = actions;
export const {addRow, deleteRow, loadData, updateRow} = retirementFundsSlice;

export default reducer;