import {Route, Routes as DomRoutes} from "react-router-dom";
import React from "react";

export default function Routes(props) {
    const routes = props.routes
    const child = props.child

    if (routes === undefined || routes === null) {
        return null
    }

    let res = [];
    const cb = (route, index) => {
      res.push(<Route key={index} path={route.path} exact={route.exact} element={<route.component {...props} routes={route.routes}/>}/>);
      route.routes && route.routes.forEach(cb);
    };
    routes.forEach(cb);
    console.log("Res: ", res);

    let result = routes.map((route, index) => (
        <Route key={index} path={route.path} exact={route.exact} element={<route.component {...props} routes={route.routes} child={true}/>}/>
    ));

    result = res

    if (child === true) {
      console.log("Routes ", routes, "is a child")
      //parentElement = undefined
      return (<>{result}</>)
    } else {
      console.log("Routes ", routes, "is not a child", child)
      return (<DomRoutes>{result}</DomRoutes>)
    }

/*    return (
        <DomRoutes>
            {routes.map((route, index) => (
                <Route key={index} path={route.path} exact={route.exact} element={<route.component {...props} routes={route.routes}/>}>
                  <Routes routes={route.routes}/>
                </Route>
            ))}
        </DomRoutes>
    );*/
}
