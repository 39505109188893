import createDataSlice from "./data.slice.creater";
import {select as selectRetirementFund} from "./retirement_funds";

const retirementFundDepositsSlice = new createDataSlice({
    sliceName: 'retirement_fund_deposits',
    baseUrl: 'retirement_funds/deposit',
    parentSelectedAction: selectRetirementFund,
    updatable: true,
    deletable: true,
    addable: true,
});

const {slice} = retirementFundDepositsSlice;
const {actions, reducer} = slice;
export const {addRow, deleteRow, loadData, updateRow} = retirementFundDepositsSlice;

export default reducer;