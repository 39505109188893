import createDataSlice from "./data.slice.creater";

const plannedDepositSlice = new createDataSlice({
    sliceName: 'planned_deposit',
    baseUrl: 'states_typed/planned_deposit',
});

const {slice} = plannedDepositSlice;
const {actions, reducer} = slice;
export const {loadData} = plannedDepositSlice;

export default reducer;