import createDataSlice from "./data.slice.creater";

const plannedIncomesStatesSlice = new createDataSlice({
    sliceName: 'planned_incomes_states',
    baseUrl: 'planned_incomes/states',
    //reloadOnDateChanged: true,
});

const {slice} = plannedIncomesStatesSlice;
const {actions, reducer} = slice;
export const {loadData} = plannedIncomesStatesSlice;

export default reducer;