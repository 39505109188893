import createDataSlice from "./data.slice.creater";

import {select as selectFiniteTarget} from "./finite_targets";

const finiteTargetStatesSlice = new createDataSlice({
    sliceName: 'finite_target_states',
    baseUrl: 'finite_targets/states',
    parentSelectedAction: selectFiniteTarget,
    recountable: true,
});

const {slice} = finiteTargetStatesSlice;
const {actions, reducer} = slice;
export const {loadData, recount} = finiteTargetStatesSlice;

export default reducer;