import React from "react";
import { connect } from 'react-redux'
import { PieChart } from 'react-minimal-pie-chart';
import randomColor from "randomcolor";

class StatesDepositsPieChartComponent extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        const lineWidth = 60;

        return(
            <div style={{ padding: "10px 10%" }}>
        <PieChart
            style={{
                fontFamily:
                    '"Nunito Sans", -apple-system, Helvetica, Arial, sans-serif',
                fontSize: '2px',
            }}
            data={ (this.props.real === true) ? this.props.data.real : this.props.data.planned }
            //radius={((PieChart.defaultProps.radius === undefined) ? 50 : PieChart.defaultProps.radius) - 2}
            radius={48}
            lineWidth={60}
            segmentsStyle={{ transition: 'stroke .3s', cursor: 'pointer' }}
            animate
            label={({ dataEntry }) => Math.round(dataEntry.percentage) + '% : ' + dataEntry.title}
            labelPosition={40 + 60 / 2}
            labelStyle={{
                fill: '#000000',
                opacity: 0.75,
                pointerEvents: 'none',
            }}
        />
            </div>
        )
    }
}

const mapStateToProps = state => {
    let real = []
    let planned = []
    for (let dep of state.states.entities) {
        let color = randomColor({luminosity: 'light'})
        if (dep.MinimumPlannedDeposit > 0.0) {
            planned.push({value: dep.MinimumPlannedDeposit, title: dep.Title, color: color})
        }
        if (dep.Deposit > 0.0) {
            real.push({value: dep.Deposit, title: dep.Title, color: color})
        }
    }
    return {
        date: state.date,
        data: {planned: planned, real: real}
    }
};


export default connect(mapStateToProps)(StatesDepositsPieChartComponent);
