import createDataSlice from "./data.slice.creater";

import {select as selectEmergencyFund} from "./refillable_funds";

const refillableFundStatesSlice = new createDataSlice({
    sliceName: 'refillable_fund_states',
    baseUrl: 'refillable_funds/states',
    parentSelectedAction: selectEmergencyFund,
    recountable: true,
});

const {slice} = refillableFundStatesSlice;
const {actions, reducer} = slice;
export const {loadData, recount} = refillableFundStatesSlice;

export default reducer;