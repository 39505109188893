import React from "react";
import MonthPicker from "./monthpicker.component";

import StatesDepositsComponent from "./states.deposits.component"
import StatesIncomesComponent from "./states.incomes.component";
import StatesPlannedIncomeStatesComponent from "./states.planned_income_states.component";
import StatesExpensesComponent from "./states.expenses.component";
import StatesAccountStatesComponent from './states.account_states.component';
import StatesStatesComponent from "./states.states.component";
import StatesIncomesRecommendedDepositComponent from "./states.incomes_recommended_deposit.component";
import DepositStateComponent from "./deposit_state.component"
import StatesDepositsPieChartComponent from "./states.deposits_pie_chart.component";

import { connect } from 'react-redux'
import {changeDate} from "../actions/date";
import {withTranslation} from "react-i18next";

class StatesComponent extends React.Component {
    constructor(props) {
        super(props);
    }

    onDatePick({year, month}) {
        console.log("onDatePick: " + year + "/" + month)
        this.props.changeDate(year, month)
    }

    render() {
        return(
            <div>
                <MonthPicker years={[2021, 2022, 2023, 2024]} onPick={this.onDatePick.bind(this)} value={this.props.date}/>
                    <div style={{padding: "10px 10%", fontFamily: "Segoe ui"}}>
                        <h2>{this.props.t('State for')} {this.props.date.month}.{this.props.date.year}</h2>
                        <StatesStatesComponent/>
                        <h2>{this.props.t('Expenses')}</h2>
                        <StatesExpensesComponent/>
                        <h2>{this.props.t('Actual Deposits')}</h2>
                        <StatesDepositsComponent/>
                        <h2>{this.props.t('Account States')}</h2>
                        <StatesAccountStatesComponent/>
                        <h2>{this.props.t('Deposit state')}</h2>
                        <DepositStateComponent />
                        <h2>{this.props.t('Incomes')}</h2>
                        <p>{this.props.t('* Zero payment means no more income this month and initiates extraction money from emergency funds')}</p>
                        <StatesIncomesComponent/>
                        <h2>{this.props.t('Incomes Recommended Deposit')}</h2>
                        {this.props.selectedIncome === undefined || this.props.selectedIncome === null ? (<p>{this.props.t('No income selected')}</p>) : (<StatesIncomesRecommendedDepositComponent />)}
                        <h2>{this.props.t('Planned Deposits')}</h2>
                        <StatesDepositsPieChartComponent/>
                        <h2>{this.props.t('Actual Deposits')}</h2>
                        <StatesDepositsPieChartComponent real={true}/>
                    </div>
            </div>
        )
    }
}

const mapStateToProps = state => ({
    date: state.date,
    selectedIncome: state.incomes.selected,
});

const mapDispatchToProps = dispatch => ({
    changeDate: (year, month) => dispatch(changeDate(year, month)),
});

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(StatesComponent));