import axiosInstance from "./api";
//import TokenService from "./token.service";
import { refreshToken } from "../actions/auth";
import {addMessage} from "../actions/messages";

///
import {parseISO} from "date-fns";
///
const isoDateFormat = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}(?:\.\d*)?(?:[-+]\d{2}:?\d{2}|Z)?$/;

function isIsoDateString(value) {
    return value && typeof value === "string" && isoDateFormat.test(value);
}

export function handleDates(body) {
    if (body === null || body === undefined || typeof body !== "object")
        return body;

    for (const key of Object.keys(body)) {
        const value = body[key];
        if (isIsoDateString(value)) body[key] = parseISO(value);
        else if (typeof value === "object") handleDates(value);
    }
}
///

const setup = (store) => {
  axiosInstance.interceptors.request.use(
    (config) => {
      console.log("(interceptors) We intercept request:", config)
      const state = store.getState(store);
      const token = state.auth.token//TokenService.getLocalAccessToken();
      if (token) {
        config.headers["Authorization"] = 'Bearer ' + token;
      }
      console.log("(interceptors) creating instance for config", config)
      return config;
    },
    (error) => {
      console.log("(interceptors) We intercept error on request:", error)
      return Promise.reject(error);
    }
  );

  const { dispatch } = store;
  axiosInstance.interceptors.response.use(
    (res) => {
      console.log("(interceptors) We intercept data response:", res)
      handleDates(res.data);
      return res;
    },
    async (err) => {
      console.log("(interceptors) We intercept error on response:", err)
      const originalConfig = err.config;

      if (originalConfig !== undefined && originalConfig.url !== "signin" && err.response) {
        console.log("(interceptors) We intercept error request:", err)
        // Access Token was expired
        if (err.response.status === 401 && !originalConfig._retry) {
          console.log("(interceptors) Making key refresh request")
          originalConfig._retry = true;

          try {
            const state = store.getState(store);
            const creds = state.auth.creds//TokenService.getLocalAccessToken();
            const response = await axiosInstance.post("signin", creds);

            //
            let authData = null
            console.log('(interceptors) Auth reply data: ', response)
            if (response != null && response !== undefined && response.data !== undefined && response.data !== null && "jwt" in response.data) {
                console.log('(interceptors) Auth token: ' + response.data.jwt)
                authData = response.data.jwt
                //dispatch(addMessage('success', 'Token refreshed successfully'))
                dispatch(refreshToken({
                    token: authData != null ? authData : null,
                    loggedIn: authData != null,
                    error: null,
                    creds: creds,
                }))
            } else {
                console.log("(interceptors) Jwt key not found auth in reply")
                dispatch(addMessage('danger', 'Error on token refresh'))
            }
            //

            //const { accessToken } = rs.data;

            //dispatch(refreshToken(accessToken));
            //TokenService.updateLocalAccessToken(accessToken);

            console.log("(interceptors) Returning new axios instance for retry", originalConfig)
            originalConfig.headers = {} // After reauth here can be wrong data (undefined and so on)
            console.log("(interceptors) Returning new axios instance for retry", originalConfig)
            return axiosInstance(originalConfig);
          } catch (_error) {
            console.log("(interceptors) Token refresh error:", _error)
            return Promise.reject(_error);
          }
        }
      }

      return Promise.reject(err);
    }
  );
};

export default setup;
