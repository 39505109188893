import React from "react";
import { connect } from 'react-redux'
import ReduxViewTableComponent from "./redux.view.table.component";
import {loadData} from "../actions/account_states";
import {withTranslation} from "react-i18next";

class StatesAccountStatesComponent extends ReduxViewTableComponent {
    constructor(props) {
        const columns = [
            {
                type: 'date',
                title: props.t('Date'),
                field: 'Date',
                total: false,
                sort: true,
            },
            {
                type: 'string',
                title: props.t('Title'),
                field: 'Title',
                total: false,
                sort: true,
                main: true,
            },
            {
                type: 'record_type',
                title: props.t('Type'),
                field: 'Type',
                total: false,
                sort: true,
            },
            {
                type: 'float',
                title: props.t('Start Amount'),
                field: 'StartAmount',
                total: true,
                sort: true,
                main: true,
            },
            {
                type: 'float',
                title: props.t('+ Deposit + Interest'),
                field: 'StartAmountDepositInterest',
                total: true,
                sort: true,
            },
            {
                type: 'float',
                title: props.t('- Expense'),
                field: 'StartAmountDepositInterestExpense',
                total: true,
                sort: true,
            },
            {
                type: 'float',
                title: props.t('% Complete'),
                field: 'CompletePct',
                total: false,
                sort: true,
            },
        ]
        super({...props, columns});
    }
}

const mapStateToProps = state => {
    let dd = []
    const recordTypeFormatter = (value) => {
        let text = "none"
        if (value != 0) {
            for (const type of state.record_types.entities) {
                if (type.Value === value) {
                    text = type.Name
                    break
                }
            }
        }
        return text
    }
    for (let dep of state.account_states.entities) {
        dd.push({...dep, Type: recordTypeFormatter(dep.Type)})
    }
    return {
    data: dd,
    error: state.account_states.error,
    isFetching: state.account_states.loading,
}};

const mapDispatchToProps = dispatch => ({
    onReloadNeeded: () => dispatch(loadData()),
});

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(StatesAccountStatesComponent));
