import createDataSlice from "./data.slice.creater";
import {select as selectEmergencyFund} from "./refillable_funds";

const refillableFundPaymentskipSlice = new createDataSlice({
    sliceName: 'monthly_expense_paymentskip',
    baseUrl: 'monthly_expenses/paymentskip',
    parentSelectedAction: selectEmergencyFund,
    updatable: true,
    deletable: true,
    addable: true,
});

const {slice} = refillableFundPaymentskipSlice;
const {actions, reducer} = slice;
export const {addRow, deleteRow, loadData} = refillableFundPaymentskipSlice;

export default reducer;