import React from "react";
import ReduxViewTableComponent from "./redux.view.table.component";
import {connect} from "react-redux";
import {withTranslation} from "react-i18next";

export function createPaymentskipTableComponent(props) {
    const {dataPath, addRow, deleteRow, loadData} = props;

    class PaymentskipTableComponent extends ReduxViewTableComponent {
        constructor(props) {
            const columns = [
                {
                    type: 'date',
                    title: props.t('Date'),
                    field: 'Date',
                    total: false,
                    sort: true,
                },
                {
                    type: 'string',
                    title: props.t('Comment'),
                    field: 'Comment',
                    total: false,
                    sort: true,
                },
            ]
            super({...props, columns});
        }
    }

    const mapStateToProps = state => ({
        data: state[dataPath].entities,
        error: state[dataPath].error,
        isFetching: state[dataPath].loading,
    });

    const mapDispatchToProps = dispatch => ({
        onReloadNeeded: () => dispatch(loadData()),
        onDelete: (row) => dispatch(deleteRow(row)),
        onAdd: (row) => dispatch(addRow(row)),
    });

    return withTranslation()(connect(mapStateToProps, mapDispatchToProps)(PaymentskipTableComponent));
}
