import createDataSlice from "./data.slice.creater";

const incomesAvgSlice = new createDataSlice({
    sliceName: 'incomes_avg',
    baseUrl: 'incomes/avg',
});

const {slice} = incomesAvgSlice;
const {actions, reducer} = slice;
export const {loadData} = incomesAvgSlice;

export default reducer;