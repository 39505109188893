import createDataSlice from "./data.slice.creater";
import {select as selectMonthlyExpense} from "./monthly_expenses";

const monthlyExpenseDepositsSlice = new createDataSlice({
    sliceName: 'monthly_expense_deposits',
    baseUrl: 'monthly_expenses/deposit',
    parentSelectedAction: selectMonthlyExpense,
    updatable: true,
    deletable: true,
    addable: true,
});

const {slice} = monthlyExpenseDepositsSlice;
const {actions, reducer} = slice;
export const {addRow, deleteRow, loadData, updateRow} = monthlyExpenseDepositsSlice;

export default reducer;