import createDataSlice from "./data.slice.creater";

import {select as selectIncome} from "./incomes";

import {loadData as depositsLoadData} from "./deposits"

const emergencyFundStatesSlice = new createDataSlice({
    sliceName: 'income_recommended_deposit',
    baseUrl: 'incomes/recommended_deposit',
    parentSelectedAction: selectIncome,
    recountable: true,
    useParentIdOnRecount: true,
    reloadDependency: [depositsLoadData],
});

const {slice} = emergencyFundStatesSlice;
const {actions, reducer} = slice;
export const {loadData, recount} = emergencyFundStatesSlice;

export default reducer;