import {Component} from "react";
import * as React from "react"
import Picker from 'react-month-picker'
import PropTypes from 'prop-types'

class MonthBox extends Component {
    static propTypes = {
        value: PropTypes.string,
        onClick: PropTypes.func,
    }

    constructor(props, context) {
        super(props, context)

        this.state = {
            value: this.props.value || 'N/A',
        }
    }

    static getDerivedStateFromProps(props, state) {
        return {
            value: props.value || 'N/A',
        }
    }

    render() {

        return (
            <div className="box" onClick={this._handleClick}>
                <label>{this.state.value}</label>
            </div>
        )
    }

    _handleClick = (e) => {
        this.props.onClick && this.props.onClick(e)
    }
}

export default class MonthPicker extends Component {
    constructor(props) {
        super(props);
        this.years = props.years ? props.years : [2021, 2022, 2023, 2024]
        this.onPick = props.onPick
        this.state = {
            monthYear: {month: props.value.month ? props.value.month : 11, year: props.value.year ? props.value.year : 2021}
        }
/*        if (props.value && props.value.month && props.value.year) {
            this.setState({...this.state, monthYear: {year: props.value.year, month: props.value.month}})
        }*/
        this.pickAMonth = React.createRef()
        this.pickerLang = {
            months: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
            from: 'From', to: 'To',
        }
    }

    setMonthYear({year, month}) {
        console.log("Month/year: " + month + "." + year)
        this.setState({...this.state, monthYear: {year, month}})
    }

    handleOnDismiss = () => {
        //this.pickAMonth.current.dismiss()
    };

    handleOnChange = (year, month) => {
        this.setMonthYear({year, month});
        this.onPick({year: year, month: month})
        this.pickAMonth.current.dismiss()
    };

    handleClickMonthBox = (e) => {
        this.pickAMonth.current.show()
    }

    makeText = m => {
        if (m && m.year && m.month) return (this.pickerLang.months[m.month-1] + '. ' + m.year)
        return '?'
    }

    render() {
        return (
            <div className="MonthYearPicker">
                <Picker
                    ref={this.pickAMonth}
                    years={this.years}
                    value={this.state.monthYear}
                    lang={this.pickerLang.months}
                    onChange={this.handleOnChange}
                    onDismiss={this.handleOnDismiss}
                >
                    <MonthBox value={this.makeText(this.state.monthYear)} onClick={this.handleClickMonthBox} />
                </Picker>
            </div>
        );
    };
}
