import createDataSlice from "./data.slice.creater";
import {loadData as depositsLoadData} from "./deposits";

const statesSlice = new createDataSlice({
    sliceName: 'states',
    baseUrl: 'states',
    reloadOnDateChanged: true,
    reloadDependency: [depositsLoadData],
    recountable: true,
});

const {slice} = statesSlice;
const {actions, reducer} = slice;
export const {loadData, recount} = statesSlice;

export default reducer;