import React from "react";
import {connect} from "react-redux";
import {Navigate, Route, Routes} from "react-router-dom";
import {logout} from "../actions/auth"

class LogoutComponent extends React.Component {
    constructor(props) {
        super(props);
    }

    componentDidMount() {
        this.props.logout()
    }

    render() {
        //return (<Redirect to="/" />)
        return (<Routes><Route path="*" element={<Navigate to="/" replace />} /></Routes>)
    }
}

const mapDispatchToProps = dispatch => ({
    logout: () => dispatch(logout())
});

export default connect(null, mapDispatchToProps)(LogoutComponent);
