import createDataSlice from "./data.slice.creater";
import {select as selectEmergencyFund} from "./emergency_funds";

const emergencyFundDepositsSlice = new createDataSlice({
    sliceName: 'emergency_fund_deposits',
    baseUrl: 'emergency_funds/deposit',
    parentSelectedAction: selectEmergencyFund,
    updatable: true,
    deletable: true,
    addable: true,
});

const {slice} = emergencyFundDepositsSlice;
const {actions, reducer} = slice;
export const {addRow, deleteRow, loadData, updateRow} = emergencyFundDepositsSlice;

export default reducer;