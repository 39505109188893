import AsyncFetchData from "./fetchData";
import {addMessage} from "./messages";
import {refreshToken} from "./auth";

export const EXPENSES_ADD_ACTION = 'expenses/add';

const expensesAddAction = (row) => {
    return async (dispatch, getState) => {
        if (row.Exists === true) {
            console.log("Expense already exists!!!")
            dispatch(addMessage('danger', 'Expense already exists!'))
            return
        }
        const expense_type = row.Type
        const target_id = row.TargetID
        let newRow = {
            Comment: '-',
            Amount: row.PlannedAmount,
            Date: row.Date,
            CurrencyID: row.CurrencyID,
        }
        try {
          const response = await AsyncFetchData(getState().auth, 'post', 'expenses', expense_type, null, target_id, newRow, () => {dispatch(refreshToken());})
          console.log('Expense create reply data: ', response)
          dispatch({
            type: EXPENSES_ADD_ACTION,
          })
        } catch (error) {
          dispatch(addMessage('danger', 'Error adding expense'))
        }
    }
};

export default expensesAddAction;
