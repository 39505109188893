import { createRoot } from 'react-dom/client';
import React from 'react';
//import { BrowserRouter } from "react-router-dom";
import './index.css';
import App from './App';
//import { createStore } from 'redux';
//import * as serviceWorker from "./serviceWorker";
//import reportWebVitals from './reportWebVitals';
//import rootReducer from "./reducers/rootReducer";

//let store = createStore(rootReducer);

import { Provider } from "react-redux";
import thunkMiddleware from 'redux-thunk'
import { createLogger } from 'redux-logger'
import { createStore, applyMiddleware } from 'redux'
import rootReducer from './reducers/rootReducer'
//import configureStore from "./store";

//const hist = createBrowserHistory();
//const history = createBrowserHistory();

import {history} from "./History";

import {asyncDispatchMiddleware} from "./AsyncDispatchMiddleware"
import './i18n';

import storage from 'redux-persist/lib/storage';
import { persistReducer, persistStore } from 'redux-persist';
import { PersistGate } from 'redux-persist/integration/react';

import setupInterceptors from "./services/setupInterceptors";

const loggerMiddleware = createLogger()

const persistConfig = {
  key: 'root',
  storage,
}

const persistedReducer = persistReducer(persistConfig, rootReducer)

const store = createStore(
    /*rootReducer*/ persistedReducer,
    applyMiddleware(
        thunkMiddleware, // позволяет нам отправлять функции
        loggerMiddleware, // аккуратно логируем экшены
        asyncDispatchMiddleware
    )
)

setupInterceptors(store);

const persistor = persistStore(store)

const container = document.getElementById('root');
const root = createRoot(container); // createRoot(container!) if you use TypeScript
root.render(
  <React.StrictMode>
      <Provider store={store} history={history}>
        <PersistGate loading={null} persistor={persistor}>
          <App />
        </PersistGate>
      </Provider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
//reportWebVitals();

//serviceWorker.unregister();
