import createDataSlice from "./data.slice.creater";
//import {selectAccount} from "./accounts";
import {select as selectAccount} from "./accounts";

const periodicalTargetSlice = new createDataSlice({
    sliceName: 'periodical_targets',
    baseUrl: 'periodical_targets',
    parentSelectedAction: selectAccount,
    updatable: true,
    deletable: true,
    addable: true,
});

const {slice} = periodicalTargetSlice;
const {actions, reducer} = slice;
export const {select} = actions;
export const {addRow, deleteRow, loadData, updateRow} = periodicalTargetSlice;

export default reducer;