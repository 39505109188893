import createDataSlice from "./data.slice.creater";

const monthlyExpensesSlice = new createDataSlice({
    sliceName: 'monthly_expenses',
    baseUrl: 'monthly_expenses',
    updatable: true,
    deletable: true,
    addable: true,
    loadOnAuth: true,
});

const {slice} = monthlyExpensesSlice;
const {actions, reducer} = slice;
export const {select} = actions;
export const {addRow, deleteRow, loadData, updateRow} = monthlyExpensesSlice;

export default reducer;