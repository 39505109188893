import createDataSlice from "./data.slice.creater";
import {select as selectCredit} from "./credits";

const creditPaymentskipSlice = new createDataSlice({
    sliceName: 'credit_paymentskip',
    baseUrl: 'credits/paymentskip',
    parentSelectedAction: selectCredit,
    updatable: true,
    deletable: true,
    addable: true,
});

const {slice} = creditPaymentskipSlice;
const {actions, reducer} = slice;
export const {addRow, deleteRow, loadData} = creditPaymentskipSlice;

export default reducer;