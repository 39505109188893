import createDataSlice from "./data.slice.creater";
import {select as selectEmergencyFund} from "./refillable_funds";

const refillableFundDepositsSlice = new createDataSlice({
    sliceName: 'refillable_fund_deposits',
    baseUrl: 'refillable_funds/deposit',
    parentSelectedAction: selectEmergencyFund,
    updatable: true,
    deletable: true,
    addable: true,
});

const {slice} = refillableFundDepositsSlice;
const {actions, reducer} = slice;
export const {addRow, deleteRow, loadData, updateRow} = refillableFundDepositsSlice;

export default reducer;