import axios from "axios";
import {parseISO} from "date-fns";
//import jwt_decode from "jwt-decode";

import api from '../services/api';

function getDateString(date) {
    console.log("getDateString(): date = ", date)
    const dateString = ("0000" + date.year).slice (-4) + '-' + ("00" + date.month).slice (-2) + '-01'
    console.log("getDateString(): formatted date is ", dateString)
    return dateString
}

const isoDateFormat = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}(?:\.\d*)?(?:[-+]\d{2}:?\d{2}|Z)?$/;

function isIsoDateString(value) {
    return value && typeof value === "string" && isoDateFormat.test(value);
}

export function handleDates(body) {
    if (body === null || body === undefined || typeof body !== "object")
        return body;

    for (const key of Object.keys(body)) {
        const value = body[key];
        if (isIsoDateString(value)) body[key] = parseISO(value);
        else if (typeof value === "object") handleDates(value);
    }
}

export default async function AsyncFetchData(auth, method, url, type, date, parent_id, data, onTokenRefreshNeeded) {
    const token = auth.token // useSelector(state => state.auth.token)
    const loggedIn = auth.loggedIn // useSelector(state => state.auth.loggedIn)

/*    if (token !== undefined && token !== null && token !== "") {
      const decodedToken: { exp: number } = jwt_decode(token);
      console.log("(async) Decoded JWT: ", decodedToken);
      const currentDate = new Date();
      if (decodedToken.exp * 1000 < (currentDate.getTime() - 60 * 1000)) {
        //await store.dispatch(refreshToken());
        console.log("(async) JWT Token expired");
        if (typeof onTokenRefreshNeeded === "function") {
          console.log("(async) executing token refresh function");
          onTokenRefreshNeeded();
        } else {
          console.log("(async) refresh function is not a function:", typeof onTokenRefreshNeeded);
        }
      } else {
        console.log("(async) JWT Token expires in", ((decodedToken.exp * 1000 - currentDate.getTime()) / 1000), "sec", currentDate.getTime() / 1000);
      }
    } else {
      console.log("(async) Empty JWT token");
    }*/

    let config = {
        baseURL: '/api/v1/',
        headers: {},
    }
    if (method === 'post') {
      //config.headers['Content-type'] = 'application/json'
      config.headers['Content-Type'] = 'application/json;charset=UTF-8'
      config.headers["Access-Control-Allow-Origin"] = "*"
    }
    if (loggedIn === true && token != null && token !== undefined) {
        config.headers['Authorization'] = 'Bearer ' + token
    }

    let instance = axios.create(config)

    instance.interceptors.response.use(originalResponse => {
        handleDates(originalResponse.data);
        return originalResponse;
    });

    if (type !== undefined && type != null) {
        url = type + '/' + url
    }
    if (date !== undefined && date != null) {
        url += '/' + getDateString(date)
    } else if (parent_id !== undefined && parent_id != null) {
        url += '/' + parent_id
    }
    console.log("LoggedIn: ", loggedIn)
    console.log("Token: ", token)
    console.log("URL to fetch: ", url)
    console.log("URL method: ", method)
    console.log("URL data: ", data)
    //let call = axios.get
    instance = api
    let call = instance.get
    //let call = api.get
    switch (method) {
        case 'get':
            call = instance.get
            break
        case 'post':
            call = instance.post
            break
        case 'delete':
            call = instance.delete
            break
        case 'put':
            call = instance.put
            break
        default:
            call = instance.get
    }

    try {
        const result = await call(url, data)
        console.log('Response:', result)
        return result.data;
    } catch (err) {
        console.log("Error on fetch: ", err);
        throw err;
    }
}
