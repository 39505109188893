import React from "react";
import ExpensesComponent, {createExpensesTableComponent} from "./expenses.component";
import PeriodicalTargetStatesComponent from "./periodical_target_states.component"
import ReduxViewTableComponent from "./redux.view.table.component";
import {connect} from "react-redux";

import {loadData, select, addRow, deleteRow, updateRow} from "../actions/periodical_targets"
import {
    addRow as addExpensesRow,
    deleteRow as deleteExpensesRow, loadData as loadExpensesData,
    updateRow as updateExpensesRow
} from "../actions/periodical_target_expenses";
import {withTranslation} from "react-i18next";

class PeriodicalTargetsTableComponent extends ReduxViewTableComponent {
    constructor(props) {
        const columns = [
            {
                type: 'string',
                title: props.t('Title'),
                field: 'Title',
                total: false,
                sort: true,
            },
            {
                type: 'string',
                title: props.t('Description'),
                field: 'Description',
                total: false,
                sort: true,
            },
            {
                type: 'float',
                title: props.t('Target Amount'),
                field: 'TargetAmount',
                total: true,
                sort: true,
            },
            {
                type: 'date',
                title: props.t('Start Date'),
                field: 'StartDate',
                total: false,
                sort: true,
            },
            {
                type: 'date',
                title: props.t('First Expense Date'),
                field: 'FirstExpenseDate',
                total: false,
                sort: true,
            },
            {
                type: 'int',
                title: props.t('Period Month'),
                field: 'PeriodMonth',
                total: false,
                sort: true,
            },
            {
                type: 'string',
                title: props.t('Currency'),
                field: 'Currency',
                total: false,
                sort: true,
            },
        ]
        super({...props, columns});
    }
}

const mapStateToProps = state => {
    let dd = []
    const currencyFormatter = (value) => {
        let text = "-"
        if (value !== null) {
            text = "+"
            for (const type of state.currency.entities) {
                if (type.ID === value) {
                    text = type.Symbol
                    break
                } else {
                    text = value
                }
            }
        }
        return text
    }
    for (let dep of state.periodical_targets.entities) {
        dd.push({...dep, Currency: currencyFormatter(dep.CurrencyID)})
    }
    return {
    data: dd,
    error: state.periodical_targets.error,
    isFetching: state.periodical_targets.loading,
    currency: state.currency.entities,
}};

const mapDispatchToProps = dispatch => ({
    onReloadNeeded: () => dispatch(loadData()),
    onRowSelect: (row) => dispatch(select(row)),
    onDelete: (row) => dispatch(deleteRow(row)),
    onEdit: (row) => dispatch(updateRow(row)),
    onAdd: (row) => dispatch(addRow(row)),
});

PeriodicalTargetsTableComponent = withTranslation()(connect(mapStateToProps, mapDispatchToProps)(PeriodicalTargetsTableComponent));

const PeriodicalTargetsExpenses = createExpensesTableComponent({dataPath: 'periodical_target_expenses', addRow: addExpensesRow, updateRow: updateExpensesRow, deleteRow: deleteExpensesRow, loadData: loadExpensesData})

class PeriodicalTargetsComponent extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        return(
            <div>
                <PeriodicalTargetsTableComponent parent_id={this.props.selectedAccount} />
                <h3>Expenses</h3>
                <PeriodicalTargetsExpenses />
                <h3>States</h3>
                {this.props.selectedRow != null ? (<PeriodicalTargetStatesComponent parent_id={this.props.selectedRow}/>) : (<p>No periodical target selected</p>)}
            </div>
        )
    }
}

const mapPeriodicalTargetsStatesToProps = state => ({
    selectedRow: state.periodical_targets.selected,
    selectedAccount: state.accounts.selected,
});

export default connect(mapPeriodicalTargetsStatesToProps)(PeriodicalTargetsComponent);
