import React from "react"
import {connect} from "react-redux";
import Alert from 'react-bootstrap/Alert'

import "./messages.css"
import {removeMessage} from "../actions/messages";

class MessagesComponent extends React.Component {
    constructor(props) {
        super(props);
    }

    componentDidMount() {
        this.startTimer()
    }

    componentWillUnmount() {
        clearInterval(this.timer);
    }

    startTimer() {
        this.timer = setTimeout(() => {
            for (let idx = 0; idx < this.props.messages.length; idx++) {
                const message = this.props.messages[idx]
                if (Date.now() - message.addTime > message.timeoutMs) {
                    this.props.closeMessage(idx)
                    break
                }
            }
            this.startTimer()
        }, 1000)
    }

    render() {
        const messages = this.props.messages.map((message, idx) => (
                <Alert key={idx} variant={message.type} onClose={() => this.props.closeMessage(idx)} dismissible>
                    {message.message}
                </Alert>
            ));
        return (<p className='messages'>{messages}</p>);
    }
}

const mapStateToProps = state => ({
    messages: state.messages.items
});

const mapDispatchToProps = dispatch => ({
    closeMessage: (idx) => dispatch(removeMessage(idx)),
});

export default connect(mapStateToProps, mapDispatchToProps)(MessagesComponent);