import { connect } from 'react-redux'
import ReduxViewTableComponent from "./redux.view.table.component";

import {loadData as loadExpensesData} from "../actions/expenses";
import expenseAddAction from "../actions/expenses.add.action";
import {withTranslation} from "react-i18next";

class StatesExpensesComponent extends ReduxViewTableComponent {
        constructor(props) {
            const columns = [
                {
                    type: 'date',
                    title: props.t('Date'),
                    field: 'Date',
                    total: false,
                    sort: true,
                    editable: true,
                },
                {
                    type: 'string',
                    title: props.t('Title'),
                    field: 'Title',
                    total: false,
                    sort: true,
                    main: true,
                },
                {
                    type: 'string',
                    title: props.t('Type'),
                    field: 'TypeName',
                    total: false,
                    sort: true,
                },
                {
                    type: 'float',
                    title: props.t('Amount'),
                    field: 'Amount',
                    total: true,
                    sort: true,
                },
                {
                    type: 'float',
                    title: props.t('Planned Amount'),
                    field: 'PlannedAmount',
                    total: true,
                    sort: true,
                    main: true,
                    showOnAdd: false,
                    editable: true,
                },
                {
                    type: 'string',
                    title: props.t('Currency'),
                    field: 'Currency',
                    total: false,
                    sort: true,
                    editable: true,
                },    
                {
                    type: 'int',
                    title: props.t('Exists'),
                    field: 'Exists',
                    total: false,
                    sort: true,
                    showOnAdd: false,
                },
            ]
            super({...props, columns});
        }
}

const mapStateToProps = state => {
    let dd = []
    const recordTypeFormatter = (value) => {
        let text = "none"
        if (value !== 0) {
            for (const type of state.record_types.entities) {
                if (type.Value === value) {
                    text = type.Name
                    break
                }
            }
        }
        return text
    }
    const currencyFormatter = (value) => {
        let text = "-"
        if (value !== null) {
            text = "+"
            for (const type of state.currency.entities) {
                if (type.ID === value) {
                    text = type.Symbol
                    break
                } else {
                    text = value
                }
            }
        }
        return text
    }
    for (let dep of state.expenses.entities) {
        dd.push({...dep, TypeName: recordTypeFormatter(dep.Type), Currency: currencyFormatter(dep.CurrencyID)})
    }
    return {
    data: dd,
    error: state.expenses.error,
    isFetching: state.expenses.loading,
    currency: state.currency.entities,
}};

const mapDispatchToProps = dispatch => ({
    onReloadNeeded: () => dispatch(loadExpensesData()),
    onApply: (row) => dispatch(expenseAddAction(row)),
    isRowApplyable: (row) => !row.Exists,
    //onAdd: (row) => dispatch(expenseAddAction(row)),
});

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(StatesExpensesComponent));
