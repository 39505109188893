import createDataSlice from "./data.slice.creater";

const historyPerMonthSlice = new createDataSlice({
    sliceName: 'history_per_month',
    baseUrl: 'history/per_month',
});

const {slice} = historyPerMonthSlice;
const {actions, reducer} = slice;
export const {loadData} = historyPerMonthSlice;

export default reducer;