import React from "react";
import DepositsComponent, {createDepositsTableComponent} from "./deposits.component";
import ReduxViewTableComponent from "./redux.view.table.component";
import {createPaymentskipTableComponent} from './paymentskip.component';
import {connect} from "react-redux";

import {select, addRow, updateRow, deleteRow, loadData} from "../actions/monthly_expenses";
import {addRow as addPaymentskipRow, deleteRow as deletePaymentskipRow, loadData as loadPaymentskipData} from "../actions/monthly_expense_paymentskip";
import {addRow as addDepositRow, deleteRow as deleteDepositRow, loadData as loadDepositData, updateRow as updateDepositRow} from "../actions/monthly_expense_deposits";
import {withTranslation} from "react-i18next";

class MonthlyExpensesTableComponent extends ReduxViewTableComponent {
    constructor(props) {
        const columns = [
            {
                type: 'string',
                title: props.t('Title'),
                field: 'Title',
                total: false,
                sort: true,
            },
            {
                type: 'string',
                title: props.t('Description'),
                field: 'Description',
                total: false,
                sort: true,
            },
            {
                type: 'float',
                title: props.t('Amount'),
                field: 'Amount',
                total: true,
                sort: true,
            },
            {
                type: 'int',
                title: props.t('Month Day From'),
                field: 'MonthDayFrom',
                total: true,
                sort: true,
            },
            {
                type: 'int',
                title: props.t('Month Day To'),
                field: 'MonthDayTo',
                total: true,
                sort: true,
            },
            {
                type: 'int',
                title: props.t('EmergencyFund'),
                //field: 'EmergencyFundId',
                field: 'EmergencyFund',
                total: false,
                sort: true,
            },
            {
                type: 'string',
                title: props.t('Currency'),
                field: 'Currency',
                total: false,
                sort: true,
            },
        ]
        super({...props, columns});
    }
}

const mapStateToProps = state => {
    let dd = []
    const emergencyFundFormatter = (value) => {
        let text = "-"
        if (value !== null) {
            text = "+"
            for (const type of state.emergency_funds.entities) {
                if (type.ID === value) {
                    text = type.Title
                    break
                }
            }
        }
        return text
    }
    const currencyFormatter = (value) => {
        let text = "-"
        if (value !== null) {
            text = "+"
            for (const type of state.currency.entities) {
                if (type.ID === value) {
                    text = type.Symbol
                    break
                } else {
                    text = value
                }
            }
        }
        return text
    }
    for (const dep of state.monthly_expenses.entities) {
        dd.push({...dep, EmergencyFund: emergencyFundFormatter(dep.EmergencyFundID), Currency: currencyFormatter(dep.CurrencyID)})
    }
    return {
    data: dd,
    error: state.monthly_expenses.error,
    isFetching: state.monthly_expenses.loading,
    emergency_funds: state.emergency_funds.entities,
    currency: state.currency.entities,
}};

const mapDispatchToProps = dispatch => ({
    onReloadNeeded: () => dispatch(loadData()),
    onRowSelect: (row) => dispatch(select(row)),
    onDelete: (row) => dispatch(deleteRow(row)),
    onEdit: (row) => dispatch(updateRow(row)),
    onAdd: (row) => dispatch(addRow(row)),
});

MonthlyExpensesTableComponent = withTranslation()(connect(mapStateToProps, mapDispatchToProps)(MonthlyExpensesTableComponent));

const MonthlyExpenseDeposits = createDepositsTableComponent({dataPath: 'monthly_expense_deposits', addRow: addDepositRow, updateRow: updateDepositRow, deleteRow: deleteDepositRow, loadData: loadDepositData})
const MonthlyExpensePaymentskip = createPaymentskipTableComponent({dataPath: 'monthly_expense_paymentskip', addRow: addPaymentskipRow, deleteRow: deletePaymentskipRow, loadData: loadPaymentskipData})

class MonthlyExpensesComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedRow: null,
        };
    }

    render() {

        return(
            <div>
                    <div style={{padding: "10px 10%", fontFamily: "Segoe ui"}}>

                        <MonthlyExpensesTableComponent/>

                        <h3>Deposits</h3>
                        <MonthlyExpenseDeposits />
                        <h3>Paymentskip</h3>
                        <MonthlyExpensePaymentskip />


                    </div>
            </div>
        )
    }
}

const mapMonthlyExpensesToProps = state => ({
    selectedRow: state.monthly_expenses.selected,
});

export default connect(mapMonthlyExpensesToProps)(MonthlyExpensesComponent);
