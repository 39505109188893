import createDataSlice from "./data.slice.creater";
import {loadData as depositsLoadData} from "./deposits";

const depositStateSlice = new createDataSlice({
    sliceName: 'deposit_state',
    baseUrl: 'deposit_state',
    reloadOnDateChanged: true,
    reloadDependency: [depositsLoadData],
});

const {slice} = depositStateSlice;
const {actions, reducer} = slice;
export const {loadData} = depositStateSlice;

export default reducer;