import React from "react";
import { connect } from 'react-redux'
import ReduxViewTableComponent from "./redux.view.table.component";

import {loadData, recount} from "../actions/states";
import {withTranslation} from "react-i18next";

class StatesStatesComponent extends ReduxViewTableComponent {
    constructor(props) {
        const columns = [
            {
                type: 'date',
                title: props.t('Date'),
                field: 'Date',
                total: false,
                sort: true,
            },
            {
                type: 'string',
                title: props.t('Title'),
                field: 'Title',
                total: false,
                sort: true,
                main: true,
            },
            {
                type: 'float',
                title: props.t('Deposit'),
                field: 'Deposit',
                total: true,
                sort: true,
                main: true,
            },
            {
                type: 'float',
                title: props.t('Interest'),
                field: 'Interest',
                total: true,
                sort: true,
            },
            {
                type: 'float',
                title: props.t('Expense'),
                field: 'Expense',
                total: true,
                sort: true,
            },
            {
                type: 'float',
                title: props.t('Planned Deposit'),
                field: 'MinimumPlannedDeposit',
                total: true,
                sort: true,
                main: true,
            },
            {
                type: 'float',
                title: props.t('Planned Interest'),
                field: 'PlannedInterest',
                total: true,
                sort: true,
            },
            {
                type: 'float',
                title: props.t('Planned Expense'),
                field: 'PlannedExpense',
                total: true,
                sort: true,
            },
            {
                type: 'string',
                title: props.t('P/S'),
                field: 'PaymentSkipState',
                total: false,
                sort: true,
            },
        ]
        super({...props, columns});
    }
}

/*const mapStateToProps = state => ({
    data: state.states.entities,
    error: state.states.error,
    isFetching: state.states.loading,
});*/

const mapStateToProps = state => {
    let dd = []
    const getPaymentSkipState = (row) => {
        if (row.PaymentSkip) {
            return (<font color="red">skip: {row.PaymentSkipComment}</font>)
        } else {
            return "no"
        }
    }
    for (let dep of state.states.entities) {
        dd.push({...dep, PaymentSkipState: getPaymentSkipState(dep)})
    }
    return {
        data: dd,
        error: state.states.error,
        isFetching: state.states.loading,
    }
};


const mapDispatchToProps = dispatch => ({
    onReloadNeeded: () => dispatch(loadData()),
    onRefresh: () => dispatch(recount()),
});

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(StatesStatesComponent));
