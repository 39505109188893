import React from "react";
import { connect } from 'react-redux'
import ReduxViewTableComponent from "./redux.view.table.component";
import {addRow} from "../actions/deposits"

import {loadData, recount} from "../actions/income_recommended_deposit";
import {withTranslation} from "react-i18next";

class StatesIncomesRecommendedDepositComponent extends ReduxViewTableComponent {
    constructor(props) {
        const columns = [
            {
                type: 'date',
                title: props.t('Date'),
                field: 'Date',
                total: false,
                sort: true,
            },
            {
                type: 'string',
                title: props.t('Comment'),
                field: 'Comment',
                total: false,
                sort: true,
            },
            {
                type: 'string',
                title: props.t('Type'),
                field: 'TypeName',
                total: false,
                sort: true,
                main: true,
            },
            {
                type: 'string',
                title: props.t('Title'),
                field: 'Title',
                total: false,
                sort: true,
                main: true,
            },
            {
                type: 'float',
                title: props.t('Amount'),
                field: 'Amount',
                total: true,
                sort: true,
                main: true,
                editable: true,
            },
            {
                type: 'int',
                title: props.t('Exists'),
                field: 'Exists',
                total: false,
                sort: true,
            },
        ]
        super({...props, columns});
    }
}

const mapStateToProps = state => {
    let dd = []
    const recordTypeFormatter = (value) => {
        let text = "none"
        if (value != 0) {
            for (const type of state.record_types.entities) {
                if (type.Value === value) {
                    text = type.Name
                    break
                }
            }
        }
        return text
    }
    const getComment = (row) => {
        if (row.Exists) {
            return row.Comment
        } else {
            return row.Comment + " / " + row.Title
        }
    }
    for (let dep of state.income_recommended_deposit.entities) {
        dd.push({...dep, TypeName: recordTypeFormatter(dep.Type), Comment: getComment(dep)})
    }
    return {
        data: dd,
        error: state.income_recommended_deposit.error,
        isFetching: state.income_recommended_deposit.loading,
        parent_id: state.income_recommended_deposit.parent_id,
    }
};

const mapDispatchToProps = (dispatch) => ({
    onReloadNeeded: () => dispatch(loadData()),
    onApply: (row) => dispatch(addRow(row)),
    isRowApplyable: (row) => !row.Exists,
    onRefresh: () => dispatch(recount()),
});

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(StatesIncomesRecommendedDepositComponent));
