import React from "react";
import { connect } from 'react-redux'
import ReduxViewTableComponent from "./redux.view.table.component";

import {loadData, deleteRow, updateRow, addRow} from "../actions/deposits";
import {withTranslation} from "react-i18next";
//import depositsEditAction from "../actions/deposits.edit.action";
//import depositsDeleteAction from "../actions/deposits.delete.action";

class StatesDepositsComponent extends ReduxViewTableComponent {
    constructor(props) {
        const columns = [
            {
                type: 'string',
                title: props.t('Comment'),
                field: 'Comment',
                total: false,
                sort: true,
                main: true,
            },
            {
                type: 'date',
                title: props.t('Date'),
                field: 'Date',
                total: false,
                sort: true,
            },
            {
                type: 'string',
                title: props.t('Type'),
                field: 'TypeName',
                total: false,
                sort: true,
                editable: false,
            },
            {
                type: 'float',
                title: props.t('Amount'),
                field: 'Amount',
                total: true,
                sort: true,
                main: true,
            },
            {
                type: 'string',
                title: props.t('Income'),
                field: 'Income',
                total: false,
                sort: true,
            },
        ]
        super({...props, columns});
    }
}

const mapStateToProps = (state) => {
    let dd = []
    const incomeFormatter = (value) => {
        let text = "none"
        if (value != 0) {
            for (const income of state.incomes.entities) {
                if (income.ID === value) {
                    const date = (typeof income.Date === "string" ? new Date(income.Date) : income.Date).toISOString().substr(0, 10)
                    text = income.Amount + ' / ' + date + ' / ' + income.Comment
                    break
                }
            }
        }
        return text
    }
    const recordTypeFormatter = (value) => {
        let text = "none"
        if (value != 0) {
            for (const type of state.record_types.entities) {
                if (type.Value === value) {
                    text = type.Name
                    break
                }
            }
        }
        return text
    }
    for (let dep of state.deposits.entities) {
        dd.push({...dep, Income: incomeFormatter(dep.IncomeID), TypeName: recordTypeFormatter(dep.Type)})
    }
    console.log("state.incomes.entities:", state.incomes.entities)
    return {
        data: dd,
        error: state.deposits.error,
        isFetching: state.deposits.loading,
        incomes: state.incomes.entities,
    }
};

const mapDispatchToProps = dispatch => ({
    onReloadNeeded: () => dispatch(loadData()),
//    onRowSelect: PropTypes.func,
    onDelete: (row) => dispatch(deleteRow(row)),
    onEdit: (row) => dispatch(updateRow(row)),
//    onAdd: PropTypes.func,
});

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(StatesDepositsComponent));
