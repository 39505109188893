import createDataSlice from "./data.slice.creater";
import {select as selectAccount} from "./accounts";

const accountPaymentskipSlice = new createDataSlice({
    sliceName: 'account_paymentskip',
    baseUrl: 'accounts/paymentskip',
    parentSelectedAction: selectAccount,
    updatable: true,
    deletable: true,
    addable: true,
});

const {slice} = accountPaymentskipSlice;
const {actions, reducer} = slice;
export const {addRow, deleteRow, loadData} = accountPaymentskipSlice;

export default reducer;