import React from "react";
import { connect } from 'react-redux'
import ReduxViewTableComponent from "./redux.view.table.component";

import {select, addRow, updateRow, deleteRow, loadData} from "../actions/incomes";
import {withTranslation} from "react-i18next";

class StatesIncomesComponent extends ReduxViewTableComponent {
    constructor(props) {
        const columns = [
            {
                type: 'date',
                title: props.t('Date'),
                field: 'Date',
                total: false,
                sort: true,
                main: true,
            },
            {
                type: 'string',
                title: props.t('Comment'),
                field: 'Comment',
                total: false,
                sort: true,
            },
            {
                type: 'float',
                title: props.t('Amount'),
                field: 'Amount',
                total: true,
                sort: true,
                main: true,
            },
            {
                type: 'float',
                title: props.t('Deposits Amount'),
                field: 'DepositsAmount',
                total: true,
                sort: true,
                showOnAdd: false,
                editable: false,
            },
            {
                type: 'string',
                title: props.t('Currency'),
                field: 'Currency',
                total: false,
                sort: true,
            },
        ]
        super({...props, columns});
    }
}

const mapStateToProps = state => {
    let dd = []
    const currencyFormatter = (value) => {
        let text = "-"
        if (value !== null) {
            text = "+"
            for (const type of state.currency.entities) {
                if (type.ID === value) {
                    text = type.Symbol
                    break
                } else {
                    text = value
                }
            }
        }
        return text
    }
    for (let dep of state.incomes.entities) {
        dd.push({...dep, Currency: currencyFormatter(dep.CurrencyID)})
    }
    return {
    data: dd,
    error: state.incomes.error,
    isFetching: state.incomes.loading,
    currency: state.currency.entities,
}};

const mapDispatchToProps = dispatch => ({
    onReloadNeeded: () => dispatch(loadData()),
    onRowSelect: (row) => dispatch(select(row)),
    onDelete: (row) => dispatch(deleteRow(row)),
    onEdit: (row) => dispatch(updateRow(row)),
    onAdd: (row) => dispatch(addRow(row)),
});

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(StatesIncomesComponent));
