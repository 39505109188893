import React from "react";
import ReduxViewTableComponent from "./redux.view.table.component";
import {connect} from "react-redux";

import {loadData} from "../actions/states_per_month";
import {withTranslation} from "react-i18next";

class StatesPerMonthComponent extends ReduxViewTableComponent {
    constructor(props) {
        const columns = [
            {
                type: 'date',
                title: props.t('Date'),
                field: 'Date',
                total: false,
                sort: true,
            },
            {
                type: 'float',
                title: props.t('Deposit'),
                field: 'Deposit',
                total: true,
                sort: true,
            },
            {
                type: 'float',
                title: props.t('Interest'),
                field: 'Interest',
                total: true,
                sort: true,
            },
            {
                type: 'float',
                title: props.t('Expense'),
                field: 'Expense',
                total: true,
                sort: true,
            },
            {
                type: 'float',
                title: props.t('Min Planned Deposit'),
                field: 'MinimumPlannedDeposit',
                total: true,
                sort: true,
            },
            {
                type: 'float',
                title: props.t('Max Planned Deposit'),
                field: 'MaximumPlannedDeposit',
                total: true,
                sort: true,
            },
            {
                type: 'float',
                title: props.t('Planned Interest'),
                field: 'PlannedInterest',
                total: true,
                sort: true,
            },
            {
                type: 'float',
                title: props.t('Planned Expense'),
                field: 'PlannedExpense',
                total: true,
                sort: true,
            },
        ]
        super({...props, columns});
    }
}

const mapStateToProps = state => ({
    data: state.states_per_month.entities,
    error: state.states_per_month.error,
    isFetching: state.states_per_month.loading,
});

const mapDispatchToProps = dispatch => ({
    onReloadNeeded: () => dispatch(loadData()),
//    onRowSelect: PropTypes.func,
//    onDelete: PropTypes.func,
//    onEdit: PropTypes.func,
//    onAdd: PropTypes.func,
});

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(StatesPerMonthComponent));
