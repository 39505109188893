import React from "react";

import { connect } from 'react-redux'
import {loadData, resendCode, verifyEmail} from "../actions/userinfo";
import Form from "react-bootstrap/Form";
import {Button} from "react-bootstrap";
import {withTranslation} from "react-i18next";

class UserinfoComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            newRow: {}
        }
    }

    onInput = (e) => {
        this.setState({ ...this.state, newRow: {...this.state.newRow, [e.target.name]: e.target.value }});
    }

    onVerification = (e) => {
        e.preventDefault();
        const email = this.props.data.Email
        const code = this.state.newRow.code
        this.props.verifyEmail(email, code)
    }

    onResendCode = (e) => {
        //e.preventDefault();
        const email = this.props.data.Email
        this.props.resendCode(email)
    }

    render() {
        const verify = (
            <>
                <br/><br/><br/>
                <h3>{this.props.t("Email not verified!")}</h3>
                <table>
                    <tbody>
                        <tr><td>
            <Form onSubmit={this.onVerification}>
                <Form.Control size="sm" type="text" placeholder='code' name='code' onChange={this.onInput}/>
                    <Button variant="primary" type="submit">{this.props.t("Check code")}</Button>
            </Form>
                <Button onClick={() => {this.onResendCode()}}>{this.props.t("Resend code")}</Button>
                        </td></tr>
                    </tbody>
                </table>
                </>
        ) // /verify_mail, data = email, code
//        const retryVerification = () // /verify_mail/retry, data = email

        return (
            <div style={{padding: "10px 10%", fontFamily: "Segoe ui"}}>
                <h1>{this.props.t("User Information")}</h1>
                <br/><br/>
                <table>
                    <tbody>
                    <tr>
                        <td>{this.props.t("Username")}</td>
                        <td colSpan={2}>{this.props.data.username}</td>
                    </tr>
                    <tr>
                        <td>{this.props.t("Email")}</td>
                        <td>{this.props.data.email}</td>
                        <td>{this.props.data.IsVerified ? this.props.t("verified") : this.props.t("not verified")}</td>
                    </tr>
                    <tr>
                        <td>{this.props.t("Default currency")}</td>
                        <td colSpan={2}>{this.props.data.DefaultCurrency.Symbol}</td>
                    </tr>
                    <tr>
                        <td>{this.props.t("Currency conversion fee")}</td>
                        <td colSpan={2}>{this.props.data.CurrencyConversionFeePct}%</td>
                    </tr>
                    </tbody>
                </table>
                <>{this.props.data.IsVerified ? "" : verify }</>
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        data: state.userinfo.entities,
        error: state.userinfo.error,
        isFetching: state.userinfo.loading,
    }
};

const mapDispatchToProps = dispatch => ({
    onReloadNeeded: () => dispatch(loadData()),
    resendCode: (email) => dispatch(resendCode({Email: email})),
    verifyEmail: (email, code) => dispatch(verifyEmail({Email: email, Code: code}))
});

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(UserinfoComponent));