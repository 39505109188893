import createDataSlice from "./data.slice.creater";

const plannedIncomesSumSlice = new createDataSlice({
    sliceName: 'planned_incomes_sum',
    baseUrl: 'planned_incomes/sum',
});

const {slice} = plannedIncomesSumSlice;
const {actions, reducer} = slice;
export const {loadData} = plannedIncomesSumSlice;

export default reducer;