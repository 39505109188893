import React from "react";
import PeriodicalTargetsComponent from "./periodical_targets.component";
import { createInterestsTableComponent } from './interests.component';
import { createPaymentskipTableComponent } from './paymentskip.component';
import ReduxViewTableComponent from "./redux.view.table.component";
import { connect } from "react-redux";

//import AccountDepositsTableComponent from "./account_deposits.component"
import { select, addRow, updateRow, deleteRow, loadData } from "../actions/accounts";

import { createDepositsTableComponent } from "./deposits.component"
import { addRow as addDepositRow, updateRow as updateDepositRow, deleteRow as deleteDepositRow, loadData as loadDepositData } from "../actions/account_deposits";
import { addRow as addInterestRow, updateRow as updateInterestRow, deleteRow as deleteInterestRow, loadData as loadInterestData } from "../actions/account_interests";
import { addRow as addPaymentskipRow, deleteRow as deletePaymentskipRow, loadData as loadPaymentskipData } from "../actions/account_paymentskip";
import { withTranslation } from "react-i18next";

class AccountsTableComponent extends ReduxViewTableComponent {
    constructor(props) {
        const columns = [
            {
                type: 'string',
                title: props.t('Title'),
                field: 'Title',
                total: false,
                sort: true,
                main: true,
            },
            {
                type: 'string',
                title: props.t('Description'),
                field: 'Description',
                total: false,
                sort: true,
            },
            {
                type: 'float',
                title: props.t('Interest %'),
                field: 'Interest',
                total: false,
                sort: true,
            },
            {
                type: 'int',
                title: props.t('EmergencyFund'),
                //field: 'EmergencyFundId',
                field: 'EmergencyFund',
                total: false,
                sort: true,
            },
            {
                type: 'string',
                title: props.t('Currency'),
                field: 'Currency',
                total: false,
                sort: true,
            },
        ]
        super({ ...props, columns });
    }
}

const mapStateToProps = state => {
    let dd = []
    const emergencyFundFormatter = (value) => {
        let text = "-"
        if (value !== null) {
            text = "+"
            for (const type of state.emergency_funds.entities) {
                if (type.ID === value) {
                    text = type.Title
                    break
                }
            }
        }
        return text
    }
    const currencyFormatter = (value) => {
        let text = "-"
        if (value !== null) {
            text = "+"
            for (const type of state.currency.entities) {
                if (type.ID === value) {
                    text = type.Symbol
                    break
                } else {
                    text = value
                }
            }
        }
        return text
    }
    for (let dep of state.accounts.entities) {
        dd.push({ ...dep, EmergencyFund: emergencyFundFormatter(dep.EmergencyFundID), Currency: currencyFormatter(dep.CurrencyID) })
    }
    return {
        data: dd,
        error: state.accounts.error,
        isFetching: state.accounts.loading,
        emergency_funds: state.emergency_funds.entities,
        currency: state.currency.entities,
    }
};

const mapDispatchToProps = dispatch => ({
    onReloadNeeded: () => dispatch(loadData()),
    onRowSelect: (row) => dispatch(select(row)),
    onDelete: (row) => dispatch(deleteRow(row)),
    onEdit: (row) => dispatch(updateRow(row)),
    onAdd: (row) => dispatch(addRow(row)),
});

AccountsTableComponent = withTranslation()(connect(mapStateToProps, mapDispatchToProps)(AccountsTableComponent));

const AccountDeposits = createDepositsTableComponent({ dataPath: 'account_deposits', addRow: addDepositRow, updateRow: updateDepositRow, deleteRow: deleteDepositRow, loadData: loadDepositData })
const AccountInterests = createInterestsTableComponent({ dataPath: 'account_interests', addRow: addInterestRow, updateRow: updateInterestRow, deleteRow: deleteInterestRow, loadData: loadInterestData })
const AccountPaymentskip = createPaymentskipTableComponent({ dataPath: 'account_paymentskip', addRow: addPaymentskipRow, deleteRow: deletePaymentskipRow, loadData: loadPaymentskipData })

class AccountsComponent extends React.Component {
    render() {
        return (
            <div style={{ padding: "10px 10%", fontFamily: "Segoe ui" }}>
                <AccountsTableComponent />
                <h3>Periodical Targets</h3>
                {this.props.selectedRow != null ? (<PeriodicalTargetsComponent account_id={this.props.selectedRow} />) : (<p>No account selected</p>)}
                <h3>Deposits</h3>
                <AccountDeposits />
                <h3>Interests</h3>
                <AccountInterests />
                <h3>Paymentskip</h3>
                <AccountPaymentskip />
            </div>
        )
    }
}

const mapAccountsStateToProps = state => ({
    selectedRow: state.accounts.selected,
});

export default connect(mapAccountsStateToProps)(AccountsComponent);
