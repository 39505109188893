import React from "react";
import {connect} from "react-redux";
import {login, logout} from "../actions/auth";
import { withTranslation } from 'react-i18next';

class LoginComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            username: null,
            password: null,
        }
    }

    onInput = (e) => {
        console.log("Value[" + e.target.name + "] = " + e.target.value)
        this.setState({ ...this.state, [e.target.name]: e.target.value });
        console.log("New state: ", this.state)
    }

    async componentDidMount() {
        this.props.logout()
    }

    handleSubmit(e) {
        e.preventDefault();
        this.props.login(this.state.username, this.state.password)
    }

    render() {

        return (
            <div className="App">
                <div className="auth-wrapper">
                    <div className="auth-inner">

        <form onSubmit={this.handleSubmit.bind(this)}>
                <h3>{this.props.t("SignIn")} / <a href={"/signup"}>{this.props.t("SignUp")}</a></h3>

                <div className="form-group">
                    <label>{this.props.t("Username")}</label>
                    <input type="username" name="username" className="form-control" placeholder={this.props.t("Enter username")} onChange={this.onInput.bind(this)} />
                </div>

                <div className="form-group">
                    <label>{this.props.t("Password")}</label>
                    <input type="password" name="password" className="form-control" placeholder={this.props.t("Enter password")} onChange={this.onInput.bind(this)} />
                </div>

                <button type="submit" className="btn btn-primary btn-block">{this.props.t("Submit")}</button>
            </form>
                    </div>
                </div>
            </div>

        );
    }
}

const mapDispatchToProps = dispatch => ({
    login: (username, password) => dispatch(login({username, password})),
    logout: () => dispatch(logout())
});

export default withTranslation()(connect(null, mapDispatchToProps)(LoginComponent));
