import createDataSlice from "./data.slice.creater";
import AsyncFetchData from "./fetchData";
import {addMessage} from "./messages";
import {refreshToken} from "./auth";

async function addDeposit(row, thunkAPI) {
    if (row.Exists === true) {
        console.log("Deposit already exists!!!")
        thunkAPI.dispatch(addMessage('danger', 'Deposit already exists!'))
        return
    }
    const deposit_type = row.Type
    const target_id = row.TargetID
    let newRow = {
        Comment: row.Comment,
        Amount: row.Amount,
        Date: row.Date,
        IncomeID: row.IncomeID,
    }
    try {
        const response = await AsyncFetchData(thunkAPI.getState().auth, 'post', 'deposits', deposit_type, null, target_id, newRow, () => {thunkAPI.dispatch(refreshToken())})
        thunkAPI.dispatch(addMessage('success', this.sliceName + ' added successfully'))
        return {
            data: response.data,
            error: null
        }
    } catch (err) {
        thunkAPI.dispatch(addMessage('danger', 'Error adding deposit'))
        return {
            data: [],
            error: err,
        }
    }
}

async function deleteDeposit(row, thunkAPI) {
    const deposit_type = row.Type
    const target_id = row.ID
    try {
        const response = await AsyncFetchData(thunkAPI.getState().auth, 'delete', 'deposits', deposit_type, null, target_id, row, () => {thunkAPI.dispatch(refreshToken())})
        thunkAPI.dispatch(addMessage('success', 'Deposit deleted successfully'))
        return {
            error: null
        }
    } catch (err) {
        thunkAPI.dispatch(addMessage('danger', 'Error editing deposit'))
        return {
            error: err,
        }
    }
}

async function editDeposit(row, thunkAPI) {
    const deposit_type = row.Type
    const target_id = row.ParentID
    try {
        const response = await AsyncFetchData(thunkAPI.getState().auth, 'put', 'deposits', deposit_type, null, target_id, row, () => {thunkAPI.dispatch(refreshToken())})
        thunkAPI.dispatch(addMessage('success', 'Deposit updated successfully'))
        return {
            data: response.data,
            error: null
        }
    } catch (err) {
        thunkAPI.dispatch(addMessage('danger', 'Error updating deposit'))
        return {
            data: [],
            error: err,
        }
    }
}

const depositsSlice = new createDataSlice({
    sliceName: 'deposits',
    baseUrl: 'deposits',
    reloadOnDateChanged: true,
    customAddFunction: addDeposit,
    customDeleteFunction: deleteDeposit,
    customEditFunction: editDeposit,
});

const {slice} = depositsSlice;
const {actions, reducer} = slice;
export const {loadData, addRow, deleteRow, updateRow} = depositsSlice;


export default reducer;
