import createDataSlice from "./data.slice.creater";

import {select as selectEmergencyFund} from "./emergency_funds";

const emergencyFundStatesSlice = new createDataSlice({
    sliceName: 'emergency_fund_states',
    baseUrl: 'emergency_funds/states',
    parentSelectedAction: selectEmergencyFund,
    recountable: true,
});

const {slice} = emergencyFundStatesSlice;
const {actions, reducer} = slice;
export const {loadData, recount} = emergencyFundStatesSlice;

export default reducer;