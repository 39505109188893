import createDataSlice from "./data.slice.creater";
import {select as selectAccount} from "./accounts";

const accountDepositsSlice = new createDataSlice({
    sliceName: 'account_deposits',
    baseUrl: 'accounts/deposit',
    parentSelectedAction: selectAccount,
    updatable: true,
    deletable: true,
    addable: true,
});

const {slice} = accountDepositsSlice;
const {actions, reducer} = slice;
export const {addRow, deleteRow, loadData, updateRow} = accountDepositsSlice;

export default reducer;