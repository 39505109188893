import createDataSlice from "./data.slice.creater";

import {select as selectCredit} from "./credits";

const creditStatesSlice = new createDataSlice({
    sliceName: 'credit_states',
    baseUrl: 'credits/states',
    parentSelectedAction: selectCredit,
    recountable: true,
});

const {slice} = creditStatesSlice;
const {actions, reducer} = slice;
export const {loadData, recount} = creditStatesSlice;

export default reducer;