import createDataSlice from "./data.slice.creater";
import {select as selectRetirementFund} from "./retirement_funds";

const retirementFundExpensesSlice = new createDataSlice({
    sliceName: 'retirement_fund_expenses',
    baseUrl: 'retirement_funds/expense',
    parentSelectedAction: selectRetirementFund,
    updatable: true,
    deletable: true,
    addable: true,
});

const {slice} = retirementFundExpensesSlice;
const {actions, reducer} = slice;
export const {addRow, deleteRow, loadData, updateRow} = retirementFundExpensesSlice;

export default reducer;