import React, { Component } from "react";
import {getCaptcha, logout, signup} from "../actions/auth";
import {connect} from "react-redux";
import {withTranslation} from "react-i18next";

class SignUp extends Component {
    constructor(props) {
        super(props);
        this.state = {
            username: null,
            password: null,
            email: null,
        }
    }

    onInput = (e) => {
        console.log("Value[" + e.target.name + "] = " + e.target.value)
        this.setState({ ...this.state, [e.target.name]: e.target.value });
        console.log("New state: ", this.state)
    }

    async componentDidMount() {
        this.props.logout()
        this.props.getCaptcha()
    }

    reloadCaptcha() {
        this.props.getCaptcha()
    }

    handleSubmit(e) {
        e.preventDefault();
        this.props.signup(this.state.username, this.state.password, this.state.email, this.props.captcha.id, this.state.captcha_value)
    }

    render() {
        return (
            <div className="App">
                <div className="auth-wrapper">
                    <div className="auth-inner">

                        <form onSubmit={this.handleSubmit.bind(this)}>
                <h3><a href={"/"}>{this.props.t('Sign In')}</a> / {this.props.t('Sign Up')}</h3>

                <div className="form-group">
                    <label>{this.props.t('Username')}</label>
                    <input type="text" name="username" className="form-control" placeholder={this.props.t("Enter username")} onChange={this.onInput.bind(this)} />
                </div>

                <div className="form-group">
                    <label>{this.props.t('Email address')}</label>
                    <input type="email" name="email" className="form-control" placeholder={this.props.t("Enter email")} onChange={this.onInput.bind(this)} />
                </div>

                <div className="form-group">
                    <label>{this.props.t('Password')}</label>
                    <input type="password" name="password" className="form-control" placeholder={this.props.t("Enter password")} onChange={this.onInput.bind(this)} />
                </div>

                            <div className="form-group">
                            <img src={this.props.captcha.url} />
                                <br/>
                            <a onClick={() => this.reloadCaptcha()}>{this.props.t('Get new captcha')}</a>
                            </div>

                            <div className="form-group">
                                <label>{this.props.t('Captcha')}</label>
                                <input type="text" name="captcha_value" className="form-control" placeholder={this.props.t("Enter value from image")} onChange={this.onInput.bind(this)} />
                            </div>


                <button type="submit" className="btn btn-primary btn-block">Sign Up</button>
                <p className="forgot-password text-right">
                    {this.props.t('Already registered')} <a href="/">{this.props.t('sign in')}?</a>
                </p>
            </form>

                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => ({
    captcha: state.auth.captcha,
});

const mapDispatchToProps = dispatch => ({
    signup: (username, password, email, captcha_id, captcha_value) => dispatch(signup({username, password, email, captcha_id, captcha_value})),
    logout: () => dispatch(logout()),
    getCaptcha: () => dispatch(getCaptcha()),
});

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(SignUp));
