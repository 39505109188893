import ReduxViewTableComponent from "./redux.view.table.component";
import {connect} from "react-redux";
import {withTranslation} from "react-i18next";

export function createDepositsTableComponent(props) {
    const {dataPath, addRow, updateRow, deleteRow, loadData} = props;

    class DepositsTableComponent extends ReduxViewTableComponent {
        constructor(props) {
            const columns = [
                {
                    type: 'date',
                    title: props.t('Date'),
                    field: 'Date',
                    total: false,
                    sort: true,
                },
                {
                    type: 'string',
                    title: props.t('Comment'),
                    field: 'Comment',
                    total: false,
                    sort: true,
                },
                {
                    type: 'float',
                    title: props.t('Amount'),
                    field: 'Amount',
                    total: true,
                    sort: true,
                },
                {
                    type: 'string',
                    title: props.t('IncomeID'),
                    field: 'IncomeID',
                    total: false,
                    sort: true,
                },
                {
                    type: 'string',
                    title: props.t('Currency'),
                    field: 'Currency',
                    total: false,
                    sort: true,
                    readonly: true,
                },    
            ]
            super({...props, columns});
        }
    }

    const mapStateToProps = state => {
        let dd = []
        const incomeFormatter = (value) => {
            let text = "none"
            if (value !== 0) {
                for (const income of state.incomes.entities) {
                    if (income.ID === value) {
                        const date = (typeof income.Date === "string" ? new Date(income.Date) : income.Date).toISOString().substr(0, 10)
                        text = income.Amount + ' / ' + date + ' / ' + income.Comment
                        break
                    }
                }
            }
            return text
        }
        const currencyFormatter = (value) => {
            let text = "-"
            if (value !== null) {
                text = "+"
                for (const type of state.currency.entities) {
                    if (type.ID === value) {
                        text = type.Symbol
                        break
                    } else {
                        text = value
                    }
                }
            }
            return text
        }    
        for (let dep of state[dataPath].entities) {
            dd.push({...dep, IncomeID: incomeFormatter(dep.IncomeID), Currency: currencyFormatter(dep.CurrencyID)})
        }
        return {
            data: dd,
            error: state[dataPath].error,
            isFetching: state[dataPath].loading,
            currency: state.currency.entities,
        }
    };

    const mapDispatchToProps = dispatch => ({
        onReloadNeeded: () => dispatch(loadData()),
        onDelete: (row) => dispatch(deleteRow(row)),
        onEdit: (row) => dispatch(updateRow(row)),
        onAdd: (row) => dispatch(addRow(row)),
    });

    return withTranslation()(connect(mapStateToProps, mapDispatchToProps)(DepositsTableComponent));
}
