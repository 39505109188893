import React from "react";
import ReduxViewTableComponent from "./redux.view.table.component";
import {connect} from "react-redux";

import {select, addRow, updateRow, deleteRow, loadData} from "../actions/planned_incomes";
import {withTranslation} from "react-i18next";


class PlannedIncomesTableComponent extends ReduxViewTableComponent {
    constructor(props) {
        const columns = [
            {
                type: 'string',
                title: props.t('Comment'),
                field: 'Comment',
                total: false,
                sort: true,
            },
            {
                type: 'float',
                title: props.t('Amount'),
                field: 'Amount',
                total: true,
                sort: true,
            },
            {
                type: 'date',
                title: props.t('Start Date'),
                field: 'StartDate',
                total: false,
                sort: true,
            },
            {
                type: 'date',
                title: props.t('End Date'),
                field: 'EndDate',
                total: false,
                sort: true,
            },
            {
                type: 'int',
                title: props.t('Month Day'),
                field: 'MonthDay',
                total: false,
                sort: true,
            },
            {
                type: 'string',
                title: props.t('Currency'),
                field: 'Currency',
                total: false,
                sort: true,
            },
        ]
        super({...props, columns});
    }
}

const mapStateToProps = state => {
    let dd = []
    const currencyFormatter = (value) => {
        let text = "-"
        if (value !== null) {
            text = "+"
            for (const type of state.currency.entities) {
                if (type.ID === value) {
                    text = type.Symbol
                    break
                } else {
                    text = value
                }
            }
        }
        return text
    }
    for (let dep of state.planned_incomes.entities) {
        dd.push({...dep, Currency: currencyFormatter(dep.CurrencyID)})
    }
    return {
    data: dd,
    error: state.planned_incomes.error,
    isFetching: state.planned_incomes.loading,
    currency: state.currency.entities,
}};


const mapDispatchToProps = dispatch => ({
    onReloadNeeded: () => dispatch(loadData()),
//    onRowSelect: (row) => dispatch(select(row)),
    onDelete: (row) => dispatch(deleteRow(row)),
    onEdit: (row) => dispatch(updateRow(row)),
    onAdd: (row) => dispatch(addRow(row)),
});

PlannedIncomesTableComponent = withTranslation()(connect(mapStateToProps, mapDispatchToProps)(PlannedIncomesTableComponent));

class PlannedIncomesComponent extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {

        return(
            <div>
                <div style={{padding: "10px 10%", fontFamily: "Segoe ui"}}>
                    <PlannedIncomesTableComponent/>
                </div>
            </div>
        )
    }
}

export default PlannedIncomesComponent;
