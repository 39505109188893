import createDataSlice from "./data.slice.creater";
import {select as selectRetirementFund} from "./retirement_funds";

const retirementFundInterestsSlice = new createDataSlice({
    sliceName: 'retirement_fund_interests',
    baseUrl: 'retirement_funds/interest',
    parentSelectedAction: selectRetirementFund,
    updatable: true,
    deletable: true,
    addable: true,
});

const {slice} = retirementFundInterestsSlice;
const {actions, reducer} = slice;
export const {addRow, deleteRow, loadData, updateRow} = retirementFundInterestsSlice;

export default reducer;