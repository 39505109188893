import React from "react";
import {connect} from "react-redux";
import {getVersion} from "../Version";
import {withTranslation} from "react-i18next";

class HowtoComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            lang: 'en',
        };
    }

    chLang() {
        if (this.state.lang === 'en') {
            this.setState({...this.state, lang: 'ru'})
        } else {
            this.setState({...this.state, lang: 'en'})
        }
    }

    render() {
        const langButton = <table border={0} width={'100%'}><tr><td align={'right'}>Change language / смена языка: <button onClick={()=>{this.chLang()}}>{this.state.lang}</button></td></tr></table>

        let text = (<>
            <h3>{this.props.t("How To")}</h3>
            <ul>
                <li>Add all your expenses, plans, financial goals and loans. For comparison, you can add planned income.</li>
                <li>Look at the plan charts and adjust your goals to match your income.</li>
                <li>On income, go to the status tab and add income to the table, then see recommended deposits and divide your income by real accounts. Apply deposits by clicking the checkmark button.</li>
                <li>For interest payments, add them on the status tab to the appropriate tables.</li>
                <li>For planned recurring expenses, get money from appropriate bank account and apply it on states tab.</li>
                <li>For unplanned expenses in refillable funds, add them to corresponding fund in add / edit tab.</li>
                <li><font color={'red'}>!!! In case of income loss add zero income - recommended deposit for this income will be negative for emergency funds and positive for reserved funds - transfer money from emergency fund bank account to needed accounts, then apply these deposits by clicking checkmark button.</font></li>
                <li>Repeat this monthly or every income / expense associated with data you filled out.</li>
            </ul>
            <h4>Target types</h4>
            <ul>
                <li>Periodical target - infinite periodical goals like taxes, new tires, yearly vacation and so on</li>
                <li>Finite target - finite goals, something with predefined date</li>
                <li>Emergency fund - fund in case of loss of income, the size of the fund is calculated automatically based on dependent goals</li>
                <li>Refillable fund - fund for periodical unplanned expenses</li>
                <li>Retirement fund - long target for passive income, investments, retirement</li>
                <li>Monthly expense - some fixed costs without details: rent, meals</li>
                <li>Credit - credits</li>
                <li>Planned income - planned income to display on the chart, just for information</li>
            </ul>
            <h4>State Tab</h4>
            <p>The state tab shows the status for the selected month. You can change the month by clicking on it (upper left corner).</p>
            <ul>
                <li>The top table shows planned and real expenses/deposits/interests for the selected month.</li>
                <li>The table of expenses shows the expenses for the selected period, also you can also add planned expenses - they are shown with the checkmark on the right.</li>
                <li>The table of actual deposits shows the deposits of the selected month.</li>
                <li>The account states table shows the expected balance of accounts at the beginning of the month and after deposits/interests/expenses.</li>
                <li>The Deposits state table shows expected amount of deposits of different levels:
                    <ul>
                        <li>Obligatory deposits are deposits to funds and goals with emergency funds selected, credits and monthly expenses</li>
                        <li>Planned deposits are all other goals</li>
                        <li>Optional deposits are deposits for overpayments</li>
                    </ul>
                </li>
                <li>The incomes table shows incomes of selected month, you can also add incomes here.</li>
                <li>The incomes recommended deposit table shows the recommended deposits for the selected income depending on the current states. You can apply the deposit by clicking checkmark button.</li>
            </ul>
            <h4>Plan Tab</h4>
            <p>The plan shows two diagrams</p>
            <ul>
                <li>The first chart shows the total planned deposits, interest and expenses. Planned and real average incomes are also shown for comparison.</li>
                <li>The second chart shows detailed expected deposits. You can also compare it with planned and actual average incomes.</li>
            </ul>
            <h4>Build information</h4>
            <p>Version: {getVersion()}</p>
            <p>Back version: {this.props.version}</p>
        </>)

        if (this.state.lang === 'ru') {
            text = (<>
                <h3>Как этим пользоваться</h3>
                <ul>
                    <li>Внесите в приложение все ваши траты, планы, цели и кредиты. Для анализа так же можно внести ожидаемый ежемесячный доход.</li>
                    <li>Посмотрите на план, скорректируйте цели, чтобы они соответствовали ожидаемому доходу.</li>
                    <li>При поступлении дохода внесите его на вкладке State, посмотрите рекомендации по разделению дохода между целями. Разделите доход и примените это в таблице с рекомендованными депозитами.</li>
                    <li>При поступлении процентов по счетам внесите их во вкладке State.</li>
                    <li>Для запланированных периодических трат снимите деньги со счета и примените это на вкладке State нажав кнопку-гаочку.</li>
                    <li>Для незапланированных расходов из восполняемых фондов внесите их во вкладке Add / Edit соответствующего фонда.</li>
                    <li><font color={'red'}>!!! В случае потери дохода внесите нулевой доход в таблицу - рекомендованные взносы для этого дохода будут отрицательные для резервных фондов и положительные для резервированных целей. Переложите деньги со счетов резервных фондов на зарезервированные цели и примените это нажав на кнопку-галочку.</font></li>
                    <li>Повторяйте это ежемесячно либо в попент поступления доходов или расходов.</li>
                </ul>
                <h4>Типы целей</h4>
                <ul>
                    <li>Periodical target - безконечные периодические траты типа налогов, новой резины, отдых каждый год и т.п., цели сгруппированы по счетам, на этих счетах происходит накопление средств</li>
                    <li>Finite target - конечная цель с заранее известным сроком</li>
                    <li>Emergency fund - резернвые фонды на случай потери дохода, размер рассчитывается автоматически на основании зависимых целей, в параметрах задается на сколько месяцев должно хватать фонда и за сколько месяцев он накапливается (можно начать с 3 / 12)</li>
                    <li>Refillable fund - восполняемые фонды на расходы с плавающей суммой, например коммуналка</li>
                    <li>Retirement fund - длительные цели для получения пассивного дохода - инвестиции, пенсия</li>
                    <li>Monthly expense - ежемесячные расходы без детализации: аренда, продукты</li>
                    <li>Credit - кредиты</li>
                    <li>Planned income - планируемый ежемесячный доход, просто для отображения на графиках</li>
                </ul>
                <h4>Вкладка State</h4>
                <p>На вкладке отображается состояние выбранного месяца. Месяц можно изменить нажав на нем (левый верхний угол).</p>
                <ul>
                    <li>Первая таблица содержит запланированные и реальные цифры по тратам, депозитам и процентам.</li>
                    <li>Таблица Expenses содержит траты выбранного месяца. Также можно применить запланированные траты - они отображаются с кнопкой-галочкой справа.</li>
                    <li>Таблица Actual deposits содержит депозиты выбранного месяца.</li>
                    <li>Таблица Account states содержит ожидаемое состояние по счетам на начало месяца и после трат/взносов/процентов для сверки.</li>
                    <li>Таблица Deposits state содержит суммы взносов различной значимости:
                        <ul>
                            <li>Obligatory - обязательные взносы: минимальные платежи по кредитам, ежемесячные траты, взносы в фонды и цели с заданным резервным фондом.</li>
                            <li>Planned - запланированные взносы: все остальное что было запланировано - досрочное погашение кредитов, цели и фонды без резервирования.</li>
                            <li>Optional - опциональные взносы: взносы на случай незапланированных поступлений денег - незапланированное досрочное погашение кредитов, досрочное пополнение резервных фондов и прочих целей.</li>
                        </ul>
                    </li>
                    <li>Таблица Incomes содержит доходы выбранного периода.</li>
                    <li>Таблица Incomes recommended deposit содержит рекомендации по разделению дохода на депозиты. Рекомендации можно принять нажав кнопку справа от них.</li>
                </ul>
                <h4>Вкладка Plan</h4>
                <p>План содержит два графика</p>
                <ul>
                    <li>Первый график содержит заплпнированные взносы, расходы и проценты. Также на графике для сравнения отображается запланированный и средний доход.</li>
                    <li>Второй график содержит детализаци по взносам. Так же содержит запланированный и средний доход для сравнения.</li>
                </ul>
                <h4>Версия:</h4>
                <p>Version: {getVersion()}</p>
                <p>Back version: {this.props.version}</p>
            </>)
        }

        return (
            <>
                <div style={{padding: "10px 10%", fontFamily: "Segoe ui"}}>
                    <h3>{this.props.t("Contacts")}</h3>
                    <p>{this.props.t("You can ask any question or leave feedback via")} <a href={"mailto:support@fingoals.odbo.co"}>{this.props.t("e-mail")}</a> {this.props.t('or')} <a href={"https://t.me/fingoals_bot"}>telegram</a>.</p>
                    <h3>{this.props.t("How To")}</h3>
                    <ul>
                        <li>{this.props.t("Add all your expenses, plans, financial goals and loans. For comparison, you can add planned income.")}</li>
                        <li>{this.props.t("Look at the plan charts and adjust your goals to match your income.")}</li>
                        <li>{this.props.t("On income, go to the status tab and add income to the table, then see recommended deposits and divide your income by real accounts. Apply deposits by clicking the checkmark button.")}</li>
                        <li>{this.props.t("For interest payments, add them on the status tab to the appropriate tables.")}</li>
                        <li>{this.props.t("For planned recurring expenses, get money from appropriate bank account and apply it on states tab.")}</li>
                        <li>{this.props.t("For unplanned expenses in refillable funds, add them to corresponding fund in add / edit tab.")}</li>
                        <li><font color={'red'}>{this.props.t("!!! In case of income loss add zero income - recommended deposit for this income will be negative for emergency funds and positive for reserved funds - transfer money from emergency fund bank account to needed accounts, then apply these deposits by clicking checkmark button.")}</font></li>
                        <li>{this.props.t("Repeat this monthly or every income / expense associated with data you filled out.")}</li>
                    </ul>
                    <h4>{this.props.t("Target types")}</h4>
                    <ul>
                        <li>{this.props.t("Periodical target")} - {this.props.t("infinite periodical goals like taxes, new tires, yearly vacation and so on")}</li>
                        <li>{this.props.t("Finite target")} - {this.props.t("finite goals, something with predefined date")}</li>
                        <li>{this.props.t("Emergency fund")} - {this.props.t("fund in case of loss of income, the size of the fund is calculated automatically based on dependent goals")}</li>
                        <li>{this.props.t("Refillable fund")} - {this.props.t("fund for periodical unplanned expenses")}</li>
                        <li>{this.props.t("Retirement fund")} - {this.props.t("long target for passive income, investments, retirement")}</li>
                        <li>{this.props.t("Monthly expense")} - {this.props.t("some fixed costs without details: rent, meals")}</li>
                        <li>{this.props.t("Credit")} - {this.props.t("credits")}</li>
                        <li>{this.props.t("Planned income")} - {this.props.t("planned income to display on the chart, just for information")}</li>
                    </ul>
                    <h4>{this.props.t("State Tab")}</h4>
                    <p>{this.props.t("The state tab shows the status for the selected month. You can change the month by clicking on it (upper left corner).")}</p>
                    <ul>
                        <li>{this.props.t("The top table shows planned and real expenses/deposits/interests for the selected month.")}</li>
                        <li>{this.props.t("The table of expenses shows the expenses for the selected period, also you can also add planned expenses - they are shown with the checkmark on the right.")}</li>
                        <li>{this.props.t("The table of actual deposits shows the deposits of the selected month.")}</li>
                        <li>{this.props.t("The account states table shows the expected balance of accounts at the beginning of the month and after deposits/interests/expenses.")}</li>
                        <li>{this.props.t("The Deposits state table shows expected amount of deposits of different levels:")}
                            <ul>
                                <li>{this.props.t("Obligatory deposits are deposits to funds and goals with emergency funds selected, credits and monthly expenses")}</li>
                                <li>{this.props.t("Planned deposits are all other goals")}</li>
                                <li>{this.props.t("Optional deposits are deposits for overpayments")}</li>
                            </ul>
                        </li>
                        <li>{this.props.t("The incomes table shows incomes of selected month, you can also add incomes here.")}</li>
                        <li>{this.props.t("The incomes recommended deposit table shows the recommended deposits for the selected income depending on the current states. You can apply the deposit by clicking checkmark button.")}</li>
                    </ul>
                    <h4>{this.props.t("Plan Tab")}</h4>
                    <p>{this.props.t("The plan shows two diagrams")}</p>
                    <ul>
                        <li>{this.props.t("The first chart shows the total planned deposits, interest and expenses. Planned and real average incomes are also shown for comparison.")}</li>
                        <li>{this.props.t("The second chart shows detailed expected deposits. You can also compare it with planned and actual average incomes.")}</li>
                    </ul>
                    <h4>{this.props.t("Build information")}</h4>
                    <p>{this.props.t("Version")}: {getVersion()}</p>
                    <p>{this.props.t("Back version")}: {this.props.version}</p>
                </div>
            </>
        );
    }
}

const mapHowtoComponentToProps = state => ({
    version: state.version.entities.version,
});

export default withTranslation()(connect(mapHowtoComponentToProps)(HowtoComponent));
