import createDataSlice from "./data.slice.creater";
import {select as selectFiniteTarget} from "./finite_targets";

const finiteTargetInterestsSlice = new createDataSlice({
    sliceName: 'finite_target_interests',
    baseUrl: 'finite_targets/interest',
    parentSelectedAction: selectFiniteTarget,
    updatable: true,
    deletable: true,
    addable: true,
});

const {slice} = finiteTargetInterestsSlice;
const {actions, reducer} = slice;
export const {addRow, deleteRow, loadData, updateRow} = finiteTargetInterestsSlice;

export default reducer;