import createDataSlice from "./data.slice.creater";
import {select as selectAccount} from "./accounts";

const accountInterestsSlice = new createDataSlice({
    sliceName: 'account_interests',
    baseUrl: 'accounts/interest',
    parentSelectedAction: selectAccount,
    updatable: true,
    deletable: true,
    addable: true,
});

const {slice} = accountInterestsSlice;
const {actions, reducer} = slice;
export const {addRow, deleteRow, loadData, updateRow} = accountInterestsSlice;

export default reducer;