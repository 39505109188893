import createDataSlice from "./data.slice.creater";

const refillableFundsSlice = new createDataSlice({
    sliceName: 'refillable_funds',
    baseUrl: 'refillable_funds',
    updatable: true,
    deletable: true,
    addable: true,
    loadOnAuth: true,
});

const {slice} = refillableFundsSlice;
const {actions, reducer} = slice;
export const {select} = actions;
export const {addRow, deleteRow, loadData, updateRow} = refillableFundsSlice;

export default reducer;