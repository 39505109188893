import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import HowtoComponent from "./components/howto.component";
import AddComponent from "./components/add.component";
import AccountsComponent from "./components/accounts.component";
import FiniteTargetsComponent from "./components/finite_targets.component";
import EmergencyFundsComponent from "./components/emergency_funds.component";
import RefillableFundsComponent from "./components/refillable_funds.component";
import RetirementFundsComponent from "./components/retirement_funds.component";
import MonthlyExpensesComponent from "./components/monthly_expenses.component";
import CreditsComponent from "./components/credits.component";
import PlannedIncomesComponent from "./components/planned_incomes.component";
import StatesComponent from "./components/states.component";
import PlanComponent from "./components/plan.component";
import DonateComponent from "./components/donate.component";
import LogoutComponent from "./components/logout.component";
import SignUp from "./components/signup.component";
import {Line, ReferenceLine} from "recharts";
import React from "react";

i18n
    // detect user language
    // learn more: https://github.com/i18next/i18next-browser-languageDetector
    .use(LanguageDetector)
    // pass the i18n instance to react-i18next.
    .use(initReactI18next)
    // init i18next
    // for all options read: https://www.i18next.com/overview/configuration-options
    .init({
        debug: true,
        fallbackLng: 'en',
        interpolation: {
            escapeValue: false, // not needed for react as it escapes by default
        },
        resources: {
            en: {
                translation: {
                    Home: "Home",
                    HowTo: "HowTo",
                    AddEdit: "Add / Edit",
                    PeriodicalTarget: "Periodical target",
                    FiniteTarget: "Finite target",
                    EmergencyFund: "Emergency fund",
                    RefillableFund: "Refillable fund",
                    RetirementFund: "Retirement fund",
                    MonthlyExpense: "Monthly expense",
                    Credit: "Credit",
                    PlannedIncome: "Planned income",
                    States: "States",
                    Plan: "Plan",
                    Donate: "Donate",
                    Logout: "Logout",
                    SignUp: "SignUp",
                    SignIn: "SignIn",
                    Login: "Login",
                    Username: "Username",
                    Password: "Password",
                    'Enter username': "Enter username",
                    'Enter password': "Enter password",
                    Submit: "Submit",
                }
            },
            ru: {
                translation: {
                    Home: "Информация",
                    HowTo: "Помощь",
                    AddEdit: "Добавить / Изменить",
                    PeriodicalTarget: "Периодические цели",
                    FiniteTarget: "Конечные цели",
                    EmergencyFund: "Резервный фонд",
                    RefillableFund: "Восполняемый фонд",
                    RetirementFund: "Пенсионный фонд",
                    MonthlyExpense: "Ежемесячные расходы",
                    Credit: "Кредит",
                    PlannedIncome: "Планируемый доход",
                    States: "Состояние",
                    Plan: "План",
                    Donate: "Поддержать",
                    Logout: "Выйти",
                    SignUp: "Регистрация",
                    SignIn: "Вход",
                    Login: "Вход",
                    Username: "Имя",
                    Password: "Пароль",
                    'Enter username': "Введите имя",
                    'Enter password': "Введите пароль",
                    Submit: "Войти",
                    Email: "Почта",
                    verified: "подтвержден",
                    'User Information': "Информация о пользователе",
                    Version: "Версия",
                    'Back version': "Версия сервера",
                    'Build information': "Инофрмация о сборке",
                    "How To": "Как этим пользоваться",
                    "Add all your expenses, plans, financial goals and loans. For comparison, you can add planned income.": "Внесите в приложение все ваши траты, планы, цели и кредиты. Для анализа так же можно внести ожидаемый ежемесячный доход.",
                    "Look at the plan charts and adjust your goals to match your income.": "Посмотрите на план, скорректируйте цели, чтобы они соответствовали ожидаемому доходу.",
                    "On income, go to the status tab and add income to the table, then see recommended deposits and divide your income by real accounts. Apply deposits by clicking the checkmark button.": "При поступлении дохода внесите его на вкладке State, посмотрите рекомендации по разделению дохода между целями. Разделите доход и примените это в таблице с рекомендованными депозитами.",
                    "For interest payments, add them on the status tab to the appropriate tables.": "При поступлении процентов по счетам внесите их во вкладке State.",
                    "For planned recurring expenses, get money from appropriate bank account and apply it on states tab.": "Для запланированных периодических трат снимите деньги со счета и примените это на вкладке State нажав кнопку-гаочку.",
                    "For unplanned expenses in refillable funds, add them to corresponding fund in add / edit tab.": "Для незапланированных расходов из восполняемых фондов внесите их во вкладке Add / Edit соответствующего фонда.",
                    "!!! In case of income loss add zero income - recommended deposit for this income will be negative for emergency funds and positive for reserved funds - transfer money from emergency fund bank account to needed accounts, then apply these deposits by clicking checkmark button.": "!!! В случае потери дохода внесите нулевой доход в таблицу - рекомендованные взносы для этого дохода будут отрицательные для резервных фондов и положительные для резервированных целей. Переложите деньги со счетов резервных фондов на зарезервированные цели и примените это нажав на кнопку-галочку.",
                    "Repeat this monthly or every income / expense associated with data you filled out.": "Повторяйте это ежемесячно либо в попент поступления доходов или расходов.",
                    "Target types": "Типы целей",
                    "Periodical target": "Периодическая цель",
                    "infinite periodical goals like taxes, new tires, yearly vacation and so on": "безконечные периодические траты типа налогов, новой резины, отдых каждый год и т.п., цели сгруппированы по счетам, на этих счетах происходит накопление средств",
                    "Finite target": "Конечная цель",
                    "finite goals, something with predefined date": "конечная цель с заранее известным сроком",
                    "Emergency fund": "Резервный фонд",
                    "fund in case of loss of income, the size of the fund is calculated automatically based on dependent goals": "резернвые фонды на случай потери дохода, размер рассчитывается автоматически на основании зависимых целей, в параметрах задается на сколько месяцев должно хватать фонда и за сколько месяцев он накапливается (можно начать с 3 / 12)",
                    "Refillable fund": "Пополняемый фонд",
                    "fund for periodical unplanned expenses": "восполняемые фонды на расходы с плавающей суммой, например коммуналка",
                    "Retirement fund": "Пенсионный фонд",
                    "long target for passive income, investments, retirement": "длительные цели для получения пассивного дохода - инвестиции, пенсия",
                    "Monthly expense": "Ежемесячная трата",
                    "some fixed costs without details: rent, meals": "ежемесячные расходы без детализации: аренда, продукты",
                    "credits": "кредиты",
                    "Planned income": "Планируемый доход",
                    "planned income to display on the chart, just for information": "планируемый ежемесячный доход, просто для отображения на графиках",
                    "State Tab": "Состояние",
                    "The state tab shows the status for the selected month. You can change the month by clicking on it (upper left corner).": "На вкладке отображается состояние выбранного месяца. Месяц можно изменить нажав на нем (левый верхний угол).",
                    "The top table shows planned and real expenses/deposits/interests for the selected month.": "Первая таблица содержит запланированные и реальные цифры по тратам, депозитам и процентам.",
                    "The table of expenses shows the expenses for the selected period, also you can also add planned expenses - they are shown with the checkmark on the right.": "Таблица Expenses содержит траты выбранного месяца. Также можно применить запланированные траты - они отображаются с кнопкой-галочкой справа.",
                    "The table of actual deposits shows the deposits of the selected month.": "Таблица Actual deposits содержит депозиты выбранного месяца.",
                    "The account states table shows the expected balance of accounts at the beginning of the month and after deposits/interests/expenses.": "Таблица Account states содержит ожидаемое состояние по счетам на начало месяца и после трат/взносов/процентов для сверки.",
                    "The Deposits state table shows expected amount of deposits of different levels:": "Таблица Deposits state содержит суммы взносов различной значимости:",
                    "Obligatory deposits are deposits to funds and goals with emergency funds selected, credits and monthly expenses": "Obligatory - обязательные взносы: минимальные платежи по кредитам, ежемесячные траты, взносы в фонды и цели с заданным резервным фондом.",
                    "Planned deposits are all other goals": "Planned - запланированные взносы: все остальное что было запланировано - досрочное погашение кредитов, цели и фонды без резервирования.",
                    "Optional deposits are deposits for overpayments": "Optional - опциональные взносы: взносы на случай незапланированных поступлений денег - незапланированное досрочное погашение кредитов, досрочное пополнение резервных фондов и прочих целей.",
                    "The incomes table shows incomes of selected month, you can also add incomes here.": "Таблица Incomes содержит доходы выбранного периода.",
                    "The incomes recommended deposit table shows the recommended deposits for the selected income depending on the current states. You can apply the deposit by clicking checkmark button.": "Таблица Incomes recommended deposit содержит рекомендации по разделению дохода на депозиты. Рекомендации можно принять нажав кнопку справа от них.",
                    "Plan Tab": "Вкладка Plan",
                    "The plan shows two diagrams": "План содержит два графика",
                    "The first chart shows the total planned deposits, interest and expenses. Planned and real average incomes are also shown for comparison.": "Первый график содержит заплпнированные взносы, расходы и проценты. Также на графике для сравнения отображается запланированный и средний доход.",
                    "The second chart shows detailed expected deposits. You can also compare it with planned and actual average incomes.": "Второй график содержит детализаци по взносам. Так же содержит запланированный и средний доход для сравнения.",

                    "FinGoals is a free project, but you can support it with a": "FinGoals - бесплатный проект, но Вы всегда можете поддержать его",
                    "donation": "добровольным взносом",
                    "Thank you!": "Спасибо!",

                    "avg income": "средний доход",
                    "planned income": "`планируемый доход",
                    "current month": "текущий месяц",

                    "MinimumPlannedDeposit": "Минимальный планируемый взнос",
                    "MaximumPlannedDeposit": "Максимальный планируемый взнос",
                    "PlannedInterest": "Планируемые проценты",
                    "PlannedExpense": "Планируемые расходы",


                    'Date': 'Дата',
                    'Deposit': 'Взнос',
                    'Interest': 'Проценты',
                    'Expense': 'Расходы',
                    'Min Planned Deposit': 'Мин. планируемый депозит',
                    'Max Planned Deposit': 'Макс. планируемый депозит',
                    'Planned Interest': 'Планируемые проценты',
                    'Planned Expense': 'Планируемые траты',

                    'Do you want to apply row?': 'Вы хотите применить изменения?',
                    'Do you want to delete row?': 'Вы хотите удалить строку?',

                    'Add': 'Добавить',
                    'Edit': 'Редактировать',
                    'Close': 'Закрыть',
                    'Save': 'Сохранить',

                    'Error on data request!': 'Ошибка получения данных!',
                    'Loading...': 'Загрузка...',
                    'No data': 'Нет данных',

                    'Total': 'Итого',

                    'Title': 'Заголовок',
                    'Description': 'Описание',
                    'Interest %': 'Доход %',

                    'Start Amount': 'Начальный баланс',
                    'End Amount': 'Конечный баланс',
                    'Planned Start Amount': 'Планируемый начальный баланс',
                    'Planned Deposit': 'Планируемый взнос',
                    'Planned Overpayment': 'Планируемая переплата',
                    'Planned Total Deposit': 'Полный планируемый взнос',
                    'Planned End Amount': 'Планируемый конечный баланс',


                    'Amount': 'Размер',
                    'Start Date': 'Дата начала',
                    'Duration Month': 'Срок погашения мес.',
                    'Desired Duration Month': 'Хелаемый срок погашения мес.',
                    'Overpayment Start Date': 'Начало переплаты',
                    'Pay Day of Month': 'День платежа',


                    'Debt': 'Долг',
                    'Deposited': 'Внесено',
                    'Rest': 'Остаток',
                    'Complete': 'Внести',

                    'Comment': 'Комментарий',
                    'IncomeID': 'ID дохода',

                    'End Date': 'Дата окончания',
                    'Target Amount': 'Целевое значение',
                    'You can ask any question or leave feedback via': 'Вы можете спросить нас или оставить отзыв в',
                    'e-mail': 'почте',
                    'Contacts': 'Контакты',
                    'or': 'или'
                }
            }
        }
    });

export default i18n;
