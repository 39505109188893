import React from "react";

import { LineChart, Line, CartesianGrid, XAxis, YAxis, Tooltip, ReferenceLine, Legend, AreaChart, Area, ResponsiveContainer } from 'recharts';
import randomColor from "randomcolor";
import StatesPerMonthComponent from "./states_per_month.component";
import {connect} from "react-redux";

import {loadData as loadPlannedDepositData} from "../actions/planned_deposit";
import {loadData as loadStatesPerMonth} from "../actions/states_per_month";
import {loadData as loadPlannedIncomesSum} from "../actions/planned_incomes_sum";
import {loadData as loadIncomesAvg} from "../actions/incomes_avg";
import {withTranslation} from "react-i18next";
import strftime from "../strftime"

class PlanComponent extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        return(
            <div>
                <PlanGraphComponent />
                <br/>
                <div style={{padding: "10px 10%", fontFamily: "Segoe ui"}}>
                    <StatesPerMonthComponent />
                </div>
            </div>
        )
    }
}
export default PlanComponent;

class PlanGraphComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            avgIncome: 0.0,
            plannedDeposits: [],
            sumPlannedIncome: 0.0,
        };
    }

    componentDidMount() {
        this.props.onReloadNeeded();
    }

    renderAreas() {
        console.log("renderLines(): plannedDeposit.Names = " + this.props.planned_deposit.entities.Names)
        const colors = this.props.planned_deposit.entities.Names ? this.props.planned_deposit.entities.Names.map(() => (
            randomColor({luminosity: 'bright'})
        )) : '';
        const lines = this.props.planned_deposit.entities.Names ? this.props.planned_deposit.entities.Names.map((value, i) => (
            <Area
                key={value}
                name={value}
                type="monotone"
                dataKey={value}
                stroke={colors[i % colors.length]}
                fill={colors[i % colors.length]}
                stackId="1"
                strokeWidth={2}
            />
        )) : '';
        return lines;
    }

    render() {
        const today = new Date();
        const year = String(today.getFullYear());
        const month = String(today.getMonth() + 1).padStart(2, '0');
        const currentMonth = year + month;
        let currentDate = today;

        console.log()

        if (this.props.states_per_month.error != null) {
            return (<>Error: {this.props.states_per_month.error}</>)
        }

        if (this.props.states_per_month.loading) {
            return (<>Loading...</>)
        }

        if (this.props.planned_deposit.error != null) {
            return (<>Error: {this.props.planned_deposit.error}</>)
        }

        if (this.props.planned_deposit.entities.loading) {
            return (<>Loading...</>)
        }

        if (this.props.states_per_month.entities === undefined) {
            return (<>No data [1]</>)
        }

        if (this.props.planned_deposit.entities === undefined) {
            return (<>No data [2]</>)
        }

        if (this.props.planned_deposit.entities.Values === undefined) {
            return (<>No data [3]</>)
        }

        if (this.props.planned_deposit.entities.Values.length === 0) {
            return (<>No data [4]</>)
        }

        if (this.props.states_per_month.entities.length === 0) {
            return (<>No data [5]</>)
        }

        for (const entity of this.props.states_per_month.entities) {
            if (entity.Date > today) {
                break
            }
            currentDate = entity.Date
        }
        console.log("Current date: ", currentDate)
        console.log("Current month: ", currentMonth)
        var minInt = this.props.planned_deposit.entities.Values[0].Date
        var maxInt = this.props.planned_deposit.entities.Values[0].Date
        for (const val of this.props.planned_deposit.entities.Values) {
            if (val.Date < minInt) {
                minInt = val.Date
            }
            if (val.Date > maxInt) {
                maxInt = val.Date
            }
        }
        var todayM = today.getMonth() - 3
        var todayY = today.getFullYear()
        if (todayM < 0) {
            todayY--
            todayM += 11
        }
        const todayInt = (todayY * 100 + todayM + 1)
        console.log("Today int: ", todayInt)
        if (minInt < todayInt) {
            minInt = todayInt
        }
        console.log("min/max int: ", minInt, maxInt)
        //
        var maxDate = new Date(Date.parse(this.props.states_per_month.entities[0].Date))
        if ((maxDate.getFullYear() * 100 + maxDate.getMonth()+1) > maxInt) {
            maxDate = new Date("1900-01-01 00:00:00")
            maxDate.setFullYear(Math.round(maxInt/100))
            maxDate.setMonth(maxInt%100)
            console.log("Fixed max", maxDate)
        }
        var minDate = new Date(Date.parse(this.props.states_per_month.entities[0].Date))
        if ((minDate.getFullYear() * 100 + minDate.getMonth()+1) < minInt) {
            minDate = new Date("1900-01-01 00:00:00")
            minDate.setFullYear(Math.round(minInt/100))
            minDate.setMonth(minInt%100)
            console.log("Fixed min", minDate)
        }
        for (const entity of this.props.states_per_month.entities) {
            const d = new Date(Date.parse(entity.Date))
            const i = d.getFullYear() * 100 + d.getMonth()+1
            if (d > maxDate && i <= maxInt) {
                maxDate = new Date(Date.parse(entity.Date))
            }
            if (d < minDate && i >= minInt) {
                minDate = new Date(Date.parse(entity.Date))
            }
        }
        console.log("Min/max date: ", minDate, maxDate)
        const maxDateInt = maxDate.getFullYear() * 100 + maxDate.getMonth()+1
        const minDateInt = minDate.getFullYear() * 100 + minDate.getMonth()+1
        console.log("Min/max date int: ", minDateInt, maxDateInt)
        //

        let states_per_month = Array();
        for (const val of this.props.states_per_month.entities) {
            const d = new Date(Date.parse(val.Date))
            if (d >= minDate && d <= maxDate) {
                states_per_month.push(val)
            }
        }

        let planned_deposit = Array();
        console.log(this.props.planned_deposit.entities)
        for (const val of this.props.planned_deposit.entities.Values) {
                if (val.Date <= maxDateInt && val.Date >= minDateInt) {
                    planned_deposit.push(val);
                }
        }
        console.log(planned_deposit)
        return (
            <>
            {this.props.states_per_month.error != null ? (<p>Error on data request!</p>) : (this.props.states_per_month.loading ? (<p>Loading...</p>) : (
                    <div style={{padding: "10px 10%", fontFamily: "Segoe ui"}}>
                        <ResponsiveContainer width="95%" height={400}>
                            <LineChart width={800} height={400} data={states_per_month} syncId="anyId">
                                <Line type="monotone" dataKey="MinimumPlannedDeposit" name={this.props.t("MinimumPlannedDeposit")} stroke="#8ae6a4" />
                                <Line type="monotone" dataKey="MaximumPlannedDeposit" name={this.props.t("MaximumPlannedDeposit")} stroke="#8ae6a4" />
                                <Line type="monotone" dataKey="PlannedInterest" name={this.props.t("PlannedInterest")} stroke="#f5db14" />
                                <Line type="monotone" dataKey="PlannedExpense" name={this.props.t("PlannedExpense")} stroke="#ff9382" />
                                <ReferenceLine y={this.props.incomes_avg.entities} label={this.props.t("avg income")} stroke="red" />
                                <ReferenceLine y={this.props.planned_incomes_sum.entities} label={this.props.t("planned income")} stroke="green" />
                                <ReferenceLine x={String(currentDate)} label={this.props.t("current month")} stroke={"red"}/>
                                <CartesianGrid stroke="#ccc" />
                                <XAxis dataKey="Date"  tickFormatter={(t) => strftime("%Y-%m", t)}/>
                                <YAxis />
                                <Tooltip />
                            </LineChart>
                        </ResponsiveContainer>
                        <br/>
                        <ResponsiveContainer width="95%" height={400}>
                            <AreaChart width={800} height={400} data={planned_deposit} syncId="anyId">
                                <XAxis dataKey="Date" tickFormatter={(t) => "" + Math.round(t/100) + "-" + String(t%100).padStart(2, '0')}/>
                                <YAxis/>
                                <Tooltip />
                                <CartesianGrid stroke="#eee" strokeDasharray="5 5"/>
                                <ReferenceLine y={this.props.incomes_avg.entities} label={this.props.t("avg income")} stroke="red" />
                                <ReferenceLine y={this.props.planned_incomes_sum.entities} label={this.props.t("planned income")} stroke="green" />
                                <ReferenceLine x={currentMonth} label={this.props.t("current month")} stroke={"red"}/>
                                {this.renderAreas()}
                            </AreaChart>
                        </ResponsiveContainer>
                    </div>
                    ))
            }
            </>
        )
    }
}

const mapGraphStateToProps = state => ({
    states_per_month: state.states_per_month,
    planned_incomes_sum: state.planned_incomes_sum,
    planned_deposit: state.planned_deposit,
    incomes_avg: state.incomes_avg,
});

const mapGraphDispatchToProps = dispatch => ({
    onReloadNeeded: () => {
        dispatch(loadStatesPerMonth());
        dispatch(loadPlannedIncomesSum());
        dispatch(loadPlannedDepositData());
        dispatch(loadIncomesAvg());
    },
});

PlanGraphComponent = withTranslation()(connect(mapGraphStateToProps, mapGraphDispatchToProps)(PlanGraphComponent));
