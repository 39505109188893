import React from "react";
import { connect } from 'react-redux'
import ReduxViewTableComponent from "./redux.view.table.component";

import {loadData} from "../actions/planned_incomes_states";
import {withTranslation} from "react-i18next";

class StatesPlannedIncomeStatesComponent extends ReduxViewTableComponent {
    constructor(props) {
        const columns = [
            {
                type: 'date',
                title: props.t('Date'),
                field: 'Date',
                total: false,
                sort: true,
                main: true,
            },
            {
                type: 'string',
                title: props.t('Comment'),
                field: 'Comment',
                total: false,
                sort: true,
            },
            {
                type: 'string',
                title: props.t('Title'),
                field: 'Title',
                total: false,
                sort: true,
            },
            {
                type: 'float',
                title: props.t('Amount'),
                field: 'Amount',
                total: true,
                sort: true,
                main: true,
            },
        ]
        super({...props, columns});
    }
}

const mapStateToProps = state => ({
    data: state.planned_incomes_states.entities,
    error: state.planned_incomes_states.error,
    isFetching: state.planned_incomes_states.loading,
});

const mapDispatchToProps = dispatch => ({
    onReloadNeeded: () => dispatch(loadData()),
//    onRowSelect: PropTypes.func,
//    onDelete: PropTypes.func,
//    onEdit: PropTypes.func,
//    onAdd: PropTypes.func,
});

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(StatesPlannedIncomeStatesComponent));
