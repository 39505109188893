import createDataSlice from "./data.slice.creater";
import {select as selectPeriodicalTarget} from "./periodical_targets";

const periodicalTargetExpensesSlice = new createDataSlice({
    sliceName: 'periodical_target_expenses',
    baseUrl: 'periodical_targets/expense',
    parentSelectedAction: selectPeriodicalTarget,
    updatable: true,
    deletable: true,
    addable: true,
});

const {slice} = periodicalTargetExpensesSlice;
const {actions, reducer} = slice;
export const {addRow, deleteRow, loadData, updateRow} = periodicalTargetExpensesSlice;

export default reducer;