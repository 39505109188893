import { createSlice } from '@reduxjs/toolkit'

const messagesSlice = createSlice({
    name: 'messages',
    initialState: {items: []},
    reducers: {
        addMessage: {
            reducer: (state, action) => {
                state.items = [
                    ...state.items,
                    action.payload,
                ]
            },
            prepare: (type, message) => {
                return {
                    payload: {
                        type: type,
                        message: message,
                        addTime: Date.now(),
                        timeoutMs: 2 * 1000
                    }
                }
            },
        },
        removeMessage: {
            reducer: (state, action) => {
                state.items = [
                    ...state.items.slice(0, action.payload),
                    ...state.items.slice(action.payload + 1)
                ]
            },
            prepare: (id) => {
                return {payload: id}
            }
        },
    },
})

// Extract the action creators object and the reducer
const { actions, reducer } = messagesSlice
// Extract and export each action creator by name
export const { addMessage, removeMessage } = actions
// Export the reducer, either as a default or named export
export default reducer
