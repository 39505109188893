import { connect } from 'react-redux'
import {loadData} from "../actions/deposit_state";
import ReduxViewTableComponent from "./redux.view.table.component";
import {withTranslation} from "react-i18next";

class DepositStateComponent extends ReduxViewTableComponent {
    constructor(props) {
        const columns = [
            {
                type: 'string',
                title: props.t('Title'),
                field: 'Title',
                total: false,
                sort: true,
                main: true,
            },
            {
                type: 'float',
                title: props.t('Debt'),
                field: 'TotalDebtAmount',
                total: false,
                sort: true,
            },
            {
                type: 'float',
                title: props.t('Deposited'),
                field: 'DepositedAmount',
                total: false,
                sort: true,
            },
            {
                type: 'float',
                title: props.t('Rest'),
                field: 'AmountRest',
                total: false,
                sort: true,
            },
            {
                type: 'string',
                title: props.t('Complete'),
                field: 'Complete',
                total: false,
                sort: true,
                main: true,
            },
        ]
        super({...props, columns});
    }
}

const mapStateToProps = state => {
    let data = []
    if ("ObligatoryTotal" in state.deposit_state.entities) {
        data.push({
            Title: "Obligatory",
            ID: 1,
            TotalDebtAmount: state.deposit_state.entities.ObligatoryTotal.TotalDebtAmount,
            DepositedAmount: state.deposit_state.entities.ObligatoryTotal.DepositedAmount,
            AmountRest: state.deposit_state.entities.ObligatoryTotal.AmountRest,
            Complete: state.deposit_state.entities.ObligatoryTotal.AmountRest <= 0,
        })
    } else {
        data.push({
            Title: "Obligatory",
            ID: 1,
            TotalDebtAmount: 0,
            DepositedAmount: 0,
            AmountRest: 0,
            Complete: false,
        })
    }
    if ("PlannedTotal" in state.deposit_state.entities) {
        data.push({
            Title: "Planned",
            ID: 2,
            TotalDebtAmount: state.deposit_state.entities.PlannedTotal.TotalDebtAmount,
            DepositedAmount: state.deposit_state.entities.PlannedTotal.DepositedAmount,
            AmountRest: state.deposit_state.entities.PlannedTotal.AmountRest,
            Complete: state.deposit_state.entities.PlannedTotal.AmountRest <= 0,
        })
    } else {
        data.push({
            Title: "Planned",
            ID: 2,
            TotalDebtAmount: 0,
            DepositedAmount: 0,
            AmountRest: 0,
            Complete: false,
        })
    }
    if ("OptionalTotal" in state.deposit_state.entities) {
        data.push({
            Title: "Optional",
            ID: 3,
            TotalDebtAmount: state.deposit_state.entities.OptionalTotal.TotalDebtAmount,
            DepositedAmount: state.deposit_state.entities.OptionalTotal.DepositedAmount,
            AmountRest: state.deposit_state.entities.OptionalTotal.AmountRest,
            Complete: state.deposit_state.entities.OptionalTotal.AmountRest <= 0,
        })
    } else {
        data.push({
            Title: "Optional",
            ID: 3,
            TotalDebtAmount: 0,
            DepositedAmount: 0,
            AmountRest: 0,
            Complete: false,
        })
    }
    return {
        data: data,
        error: state.deposit_state.error,
        isFetching: state.deposit_state.loading,
    }

};

const mapDispatchToProps = dispatch => ({
    onReloadNeeded: () => dispatch(loadData()),
});

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(DepositStateComponent));