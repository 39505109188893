import createDataSlice from "./data.slice.creater";
import {select as selectFiniteTarget} from "./finite_targets";

const finiteTargetPaymentskipSlice = new createDataSlice({
    sliceName: 'finite_target_paymentskip',
    baseUrl: 'finite_targets/paymentskip',
    parentSelectedAction: selectFiniteTarget,
    updatable: true,
    deletable: true,
    addable: true,
});

const {slice} = finiteTargetPaymentskipSlice;
const {actions, reducer} = slice;
export const {addRow, deleteRow, loadData} = finiteTargetPaymentskipSlice;

export default reducer;