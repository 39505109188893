import React from "react";
import {createInterestsTableComponent} from "./interests.component";
import FiniteTargetStatesComponent from "./finite_target_states.component";
import ReduxViewTableComponent from "./redux.view.table.component";
import {createPaymentskipTableComponent} from './paymentskip.component';
import {connect} from "react-redux";

import {select, addRow, updateRow, deleteRow, loadData} from "../actions/finite_targets";

import {createDepositsTableComponent} from "./deposits.component"
import {addRow as addDepositRow, updateRow as updateDepositRow, deleteRow as deleteDepositRow, loadData as loadDepositData} from "../actions/finite_target_deposits";
import {addRow as addInterestRow, updateRow as updateInterestRow, deleteRow as deleteInterestRow, loadData as loadInterestData} from "../actions/finite_target_interests";
import {addRow as addPaymentskipRow, deleteRow as deletePaymentskipRow, loadData as loadPaymentskipData} from "../actions/finite_target_paymentskip";
import {withTranslation} from "react-i18next";

class FiniteTargetsTableComponent extends ReduxViewTableComponent {
    constructor(props) {
        const columns = [
            {
                type: 'string',
                title: props.t('Title'),
                field: 'Title',
                total: false,
                sort: true,
            },
            {
                type: 'string',
                title: props.t('Description'),
                field: 'Description',
                total: false,
                sort: true,
            },
            {
                type: 'float',
                title: props.t('Target Amount'),
                field: 'TargetAmount',
                total: true,
                sort: true,
            },
            {
                type: 'date',
                title: props.t('Start Date'),
                field: 'StartDate',
                total: false,
                sort: true,
            },
            {
                type: 'date',
                title: props.t('End Date'),
                field: 'EndDate',
                total: false,
                sort: true,
            },
            {
                type: 'float',
                title: props.t('Interest %'),
                field: 'Interest',
                total: false,
                sort: true,
            },
            {
                type: 'string',
                title: props.t('Currency'),
                field: 'Currency',
                total: false,
                sort: true,
            },
            {
                type: 'int',
                title: props.t('EmergencyFund'),
                //field: 'EmergencyFundId',
                field: 'EmergencyFund',
                total: false,
                sort: true,
            },
        ]
        super({...props, columns});
    }
}

const mapStateToProps = state => {
    let dd = []
    const emergencyFundFormatter = (value) => {
        let text = "-"
        if (value !== null) {
            text = "+"
            for (const type of state.emergency_funds.entities) {
                if (type.ID === value) {
                    text = type.Title
                    break
                }
            }
        }
        return text
    }
    const currencyFormatter = (value) => {
        let text = "-"
        if (value !== null) {
            text = "+"
            for (const type of state.currency.entities) {
                if (type.ID === value) {
                    text = type.Symbol
                    break
                } else {
                    text = value
                }
            }
        }
        return text
    }
    var currentdate = new Date();
    for (let dep of state.finite_targets.entities) {
      const endDate = (typeof dep.EndDate === "string" ? new Date(dep.EndDate) : dep.EndDate)
        if (endDate.getFullYear() > currentdate.getFullYear() || (endDate.getFullYear() === currentdate.getFullYear() && endDate.getMonth() >= currentdate.getMonth())) {
            dd.push({...dep, EmergencyFund: emergencyFundFormatter(dep.EmergencyFundID), Currency: currencyFormatter(dep.CurrencyID)})
        }
    }
        return {
    data: dd,
    error: state.finite_targets.error,
        isFetching: state.finite_targets.loading,
        emergency_funds: state.emergency_funds.entities,
        currency: state.currency.entities,
}};

const mapHistoryStateToProps = state => {
    let dd = []
    const emergencyFundFormatter = (value) => {
        let text = "-"
        if (value !== null) {
            text = "+"
            for (const type of state.emergency_funds.entities) {
                if (type.ID === value) {
                    text = type.Title
                    break
                }
            }
        }
        return text
    }
    const currencyFormatter = (value) => {
        let text = "-"
        if (value !== null) {
            text = "+"
            for (const type of state.currency.entities) {
                if (type.ID === value) {
                    text = type.Symbol
                    break
                } else {
                    text = value
                }
            }
        }
        return text
    }
    var currentdate = new Date();
    for (let dep of state.finite_targets.entities) {
      const endDate = (typeof dep.EndDate === "string" ? new Date(dep.EndDate) : dep.EndDate)
        if (endDate.getFullYear() < currentdate.getFullYear() || (endDate.getFullYear() === currentdate.getFullYear() && endDate.getMonth() < currentdate.getMonth())) {
            dd.push({...dep, EmergencyFund: emergencyFundFormatter(dep.EmergencyFundID), Currency: currencyFormatter(dep.CurrencyID)})
        }
    }
    return {
        data: dd,
        error: state.finite_targets.error,
        isFetching: state.finite_targets.loading,
        emergency_funds: state.emergency_funds.entities,
        currency: state.currency.entities,
    }};

const mapDispatchToProps = dispatch => ({
    onReloadNeeded: () => dispatch(loadData()),
    onRowSelect: (row) => dispatch(select(row)),
    onDelete: (row) => dispatch(deleteRow(row)),
    onEdit: (row) => dispatch(updateRow(row)),
    onAdd: (row) => dispatch(addRow(row)),
});

let FiniteTargetsHistoryTableComponent = withTranslation()(connect(mapHistoryStateToProps, mapDispatchToProps)(FiniteTargetsTableComponent));
FiniteTargetsTableComponent = withTranslation()(connect(mapStateToProps, mapDispatchToProps)(FiniteTargetsTableComponent));

const FiniteTargetDeposits = createDepositsTableComponent({dataPath: 'finite_target_deposits', addRow: addDepositRow, updateRow: updateDepositRow, deleteRow: deleteDepositRow, loadData: loadDepositData})
const FiniteTargetInterests = createInterestsTableComponent({dataPath: 'finite_target_interests', addRow: addInterestRow, updateRow: updateInterestRow, deleteRow: deleteInterestRow, loadData: loadInterestData})
const FiniteTargetPaymentskip = createPaymentskipTableComponent({dataPath: 'finite_target_paymentskip', addRow: addPaymentskipRow, deleteRow: deletePaymentskipRow, loadData: loadPaymentskipData})

class FiniteTargetsComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedRow: null,
        };
    }

    render() {
        return(
            <div style={{padding: "10px 10%", fontFamily: "Segoe ui"}}>
                <FiniteTargetsTableComponent/>
                <h3>Deposits</h3>
                <FiniteTargetDeposits />
                <h3>Interests</h3>
                <FiniteTargetInterests />
                <h3>Paymentskip</h3>
                <FiniteTargetPaymentskip />
                <h3>States</h3>
                {this.props.selectedRow != null ? (<FiniteTargetStatesComponent parent_id={this.props.selectedRow}/>) : (<p>No finite target selected</p>)}
                <h3>History</h3>
                <FiniteTargetsHistoryTableComponent/>
            </div>
        )
    }
}

const mapFiniteTargetsToProps = state => ({
    selectedRow: state.finite_targets.selected,
});

export default connect(mapFiniteTargetsToProps)(FiniteTargetsComponent);
