import React from "react";
import { createInterestsTableComponent } from "./interests.component";
import RetirementFundTargetStatesComponent from "./retirement_fund_states.component";
import ReduxViewTableComponent from "./redux.view.table.component";
import { createPaymentskipTableComponent } from './paymentskip.component';
import { connect } from "react-redux";

import { select, addRow, updateRow, deleteRow, loadData } from "../actions/retirement_funds";

import { createDepositsTableComponent } from "./deposits.component"
import { createExpensesTableComponent } from "./expenses.component";
import { addRow as addDepositRow, updateRow as updateDepositRow, deleteRow as deleteDepositRow, loadData as loadDepositData } from "../actions/retirement_fund_deposits";
import { addRow as addInterestRow, updateRow as updateInterestRow, deleteRow as deleteInterestRow, loadData as loadInterestData } from "../actions/retirement_fund_interests";
import { addRow as addExpensesRow, updateRow as updateExpensesRow, deleteRow as deleteExpensesRow, loadData as loadExpensesData } from "../actions/retirement_fund_expenses";
import { addRow as addPaymentskipRow, deleteRow as deletePaymentskipRow, loadData as loadPaymentskipData } from "../actions/retirement_fund_paymentskip";
import { withTranslation } from "react-i18next";

class RetirementFundsTableComponent extends ReduxViewTableComponent {
    constructor(props) {
        const columns = [
            {
                type: 'string',
                title: props.t('Title'),
                field: 'Title',
                total: false,
                sort: true,
            },
            {
                type: 'string',
                title: props.t('Description'),
                field: 'Description',
                total: false,
                sort: true,
            },
            {
                type: 'float',
                title: props.t('Target Amount'),
                field: 'TargetAmount',
                total: true,
                sort: true,
            },
            {
                type: 'float',
                title: props.t('Month Payout Amount'),
                field: 'MonthPayoutAmount',
                total: true,
                sort: true,
            },
            {
                type: 'date',
                title: props.t('Accumulation Start Date'),
                field: 'AccumulationStartDate',
                total: false,
                sort: true,
            },
            {
                type: 'date',
                title: props.t('Payout Start Date'),
                field: 'PayoutStartDate',
                total: false,
                sort: true,
            },
            {
                type: 'date',
                title: props.t('Payout End Date'),
                field: 'PayoutEndDate',
                total: false,
                sort: true,
            },
            {
                type: 'float',
                title: props.t('Inflation %'),
                field: 'Inflation',
                total: false,
                sort: true,
            },
            {
                type: 'float',
                title: props.t('Interest While Accumulation %'),
                field: 'InterestWhileAccumulation',
                total: false,
                sort: true,
            },
            {
                type: 'float',
                title: props.t('Interest While Payout %'),
                field: 'InterestWhilePayout',
                total: false,
                sort: true,
            },
            {
                type: 'int',
                title: props.t('EmergencyFund'),
                //field: 'EmergencyFundId',
                field: 'EmergencyFund',
                total: false,
                sort: true,
            },
            {
                type: 'string',
                title: props.t('Currency'),
                field: 'Currency',
                total: false,
                sort: true,
            },
        ]
        super({ ...props, columns });
    }
}

const mapStateToProps = state => {
    let dd = []
    const emergencyFundFormatter = (value) => {
        let text = "-"
        if (value !== null) {
            text = "+"
            for (const type of state.emergency_funds.entities) {
                if (type.ID === value) {
                    text = type.Title
                    break
                }
            }
        }
        return text
    }
    const currencyFormatter = (value) => {
        let text = "-"
        if (value !== null) {
            text = "+"
            for (const type of state.currency.entities) {
                if (type.ID === value) {
                    text = type.Symbol
                    break
                } else {
                    text = value
                }
            }
        }
        return text
    }
    for (let dep of state.retirement_funds.entities) {
        dd.push({ ...dep, EmergencyFund: emergencyFundFormatter(dep.EmergencyFundID), Currency: currencyFormatter(dep.CurrencyID) })
    }
    return {
        data: dd,
        error: state.retirement_funds.error,
        isFetching: state.retirement_funds.loading,
        emergency_funds: state.emergency_funds.entities,
        currency: state.currency.entities,
    }
};


const mapDispatchToProps = dispatch => ({
    onReloadNeeded: () => dispatch(loadData()),
    onRowSelect: (row) => dispatch(select(row)),
    onDelete: (row) => dispatch(deleteRow(row)),
    onEdit: (row) => dispatch(updateRow(row)),
    onAdd: (row) => dispatch(addRow(row)),
});

RetirementFundsTableComponent = withTranslation()(connect(mapStateToProps, mapDispatchToProps)(RetirementFundsTableComponent));

const RetirementFundDeposits = createDepositsTableComponent({ dataPath: 'retirement_fund_deposits', addRow: addDepositRow, updateRow: updateDepositRow, deleteRow: deleteDepositRow, loadData: loadDepositData })
const RetirementFundInterests = createInterestsTableComponent({ dataPath: 'retirement_fund_interests', addRow: addInterestRow, updateRow: updateInterestRow, deleteRow: deleteInterestRow, loadData: loadInterestData })
const RetirementFundExpenses = createExpensesTableComponent({ dataPath: 'retirement_fund_expenses', addRow: addExpensesRow, updateRow: updateExpensesRow, deleteRow: deleteExpensesRow, loadData: loadExpensesData })
const RetirementFundPaymentskip = createPaymentskipTableComponent({ dataPath: 'retirement_fund_paymentskip', addRow: addPaymentskipRow, deleteRow: deletePaymentskipRow, loadData: loadPaymentskipData })

class RetirementFundsComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedRow: null,
        };
    }

    render() {
        return (
            <div style={{ padding: "10px 10%", fontFamily: "Segoe ui" }}>
                <RetirementFundsTableComponent />
                <h3>Deposits</h3>
                <RetirementFundDeposits />
                <h3>Expenses</h3>
                <RetirementFundExpenses />
                <h3>Interests</h3>
                <RetirementFundInterests />
                <h3>Paymentskip</h3>
                <RetirementFundPaymentskip />
                <h3>States</h3>
                {this.props.selectedRow != null ? (<RetirementFundTargetStatesComponent parent_id={this.props.selectedRow} />) : (<p>No retirement fund selected</p>)}
            </div>
        )
    }
}

const mapRetirementFundsToProps = state => ({
    selectedRow: state.retirement_funds.selected,
});

export default connect(mapRetirementFundsToProps)(RetirementFundsComponent);
