import createDataSlice from "./data.slice.creater";
import {select as selectMonthlyExpense} from "./monthly_expenses";

const monthlyExpensePaymentskipSlice = new createDataSlice({
    sliceName: 'monthly_expense_paymentskip',
    baseUrl: 'monthly_expenses/paymentskip',
    parentSelectedAction: selectMonthlyExpense,
    updatable: true,
    deletable: true,
    addable: true,
});

const {slice} = monthlyExpensePaymentskipSlice;
const {actions, reducer} = slice;
export const {addRow, deleteRow, loadData} = monthlyExpensePaymentskipSlice;

export default reducer;