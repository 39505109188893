import { combineReducers } from 'redux'

import messages from "../actions/messages"
import accounts from "../actions/accounts"
import periodical_targets from "../actions/periodical_targets"
import auth from "../actions/auth"
import date from "../actions/date"
import emergency_funds from "../actions/emergency_funds"
import emergency_fund_states from "../actions/emergency_fund_states";
import account_states from "../actions/account_states"
import credits from "../actions/credits"
import finite_targets from "../actions/finite_targets"
import monthly_expenses from "../actions/monthly_expenses"
import planned_incomes from "../actions/planned_incomes"
import states from "../actions/states"
import incomes from "../actions/incomes"
import income_recommended_deposit from "../actions/income_recommended_deposit";
import account_deposits from "../actions/account_deposits";
import credit_states from "../actions/credit_states"
import periodical_target_states from "../actions/periodical_target_states";
import planned_deposit from "../actions/planned_deposit";
import planned_incomes_states from "../actions/planned_incomes_states";
import states_per_month from "../actions/states_per_month"
import planned_incomes_sum from "../actions/planned_incomes_sum";
import incomes_avg from "../actions/incomes_avg";
import finite_target_states from "../actions/finite_target_states";
import expenses from "../actions/expenses";
import credit_deposits from "../actions/credit_deposits";
import account_interests from "../actions/account_interests";
import finite_target_deposits from "../actions/finite_target_deposits";
import finite_target_interests from "../actions/finite_target_interests";
import emergency_fund_expenses from "../actions/emergency_fund_expenses";
import emergency_fund_interests from "../actions/emergency_fund_interests";
import emergency_fund_deposits from "../actions/emergency_fund_deposits";
import monthly_expense_deposits from "../actions/monthly_expense_deposits";
import periodical_target_expenses from "../actions/periodical_target_expenses";
import record_types from "../actions/record_types";
import deposits from "../actions/deposits";
import refillable_funds from "../actions/refillable_funds";
import refillable_fund_deposits from "../actions/refillable_fund_deposits";
import refillable_fund_states from "../actions/refillable_fund_states";
import refillable_fund_interests from "../actions/refillable_fund_interests";
import refillable_fund_expenses from "../actions/refillable_fund_expenses";
import deposit_state from "../actions/deposit_state";
import userinfo from "../actions/userinfo";
import retirement_funds from "../actions/retirement_funds";
import retirement_fund_deposits from "../actions/retirement_fund_deposits";
import retirement_fund_states from "../actions/retirement_fund_states";
import retirement_fund_interests from "../actions/retirement_fund_interests";
import retirement_fund_expenses from "../actions/retirement_fund_expenses";
import version from "../actions/version";
import account_paymentskip from "../actions/account_paymentskip"
import credit_paymentskip from "../actions/credit_paymentskip"
import emergency_fund_paymentskip from "../actions/emergency_fund_paymentskip"
import finite_target_paymentskip from "../actions/finite_target_paymentskip"
import monthly_expense_paymentskip from "../actions/monthly_expense_paymentskip"
import refillable_fund_paymentskip from "../actions/refillable_fund_paymentskip"
import retirement_fund_paymentskip from "../actions/retirement_fund_paymentskip"
import history_per_month from "../actions/history_per_month"
import currency from "../actions/currency"

const rootReducer = combineReducers({
    date,
    incomes,
    planned_incomes,
    planned_incomes_states,
    planned_incomes_sum,
    states,
    states_per_month,
    expenses,
    account_states,
    income_recommended_deposit,
    accounts,
    account_deposits,
    finite_targets,
    finite_target_states,
    emergency_funds,
    emergency_fund_states,
    monthly_expenses,
    auth,
    messages,
    planned_deposit,
    credits,
    credit_states,
    periodical_targets,
    periodical_target_states,
    incomes_avg,
    credit_deposits,
    account_interests,
    finite_target_deposits,
    finite_target_interests,
    emergency_fund_expenses,
    emergency_fund_interests,
    emergency_fund_deposits,
    monthly_expense_deposits,
    periodical_target_expenses,
    record_types,
    deposits,
    refillable_funds,
    refillable_fund_states,
    refillable_fund_expenses,
    refillable_fund_interests,
    refillable_fund_deposits,
    deposit_state,
    userinfo,
    retirement_funds,
    retirement_fund_deposits,
    retirement_fund_states,
    retirement_fund_interests,
    retirement_fund_expenses,
    version,
    account_paymentskip,
    credit_paymentskip,
    emergency_fund_paymentskip,
    finite_target_paymentskip,
    monthly_expense_paymentskip,
    refillable_fund_paymentskip,
    retirement_fund_paymentskip,
    history_per_month,
    currency,
})

export default rootReducer;