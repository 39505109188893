import React from "react";
import {createInterestsTableComponent} from "./interests.component";
import RefillableFundStatesTableComponent from "./refillable_fund_states.component";
import ReduxViewTableComponent from "./redux.view.table.component";
import {createPaymentskipTableComponent} from './paymentskip.component';
import {connect} from "react-redux";

import {select, addRow, updateRow, deleteRow, loadData} from "../actions/refillable_funds";

import {createDepositsTableComponent} from "./deposits.component"
import {createExpensesTableComponent} from "./expenses.component";
import {addRow as addDepositRow, updateRow as updateDepositRow, deleteRow as deleteDepositRow, loadData as loadDepositData} from "../actions/refillable_fund_deposits";
import {addRow as addInterestRow, updateRow as updateInterestRow, deleteRow as deleteInterestRow, loadData as loadInterestData} from "../actions/refillable_fund_interests";
import {addRow as addExpensesRow, updateRow as updateExpensesRow, deleteRow as deleteExpensesRow, loadData as loadExpensesData} from "../actions/refillable_fund_expenses";
import {addRow as addPaymentskipRow, deleteRow as deletePaymentskipRow, loadData as loadPaymentskipData} from "../actions/refillable_fund_paymentskip";
import {withTranslation} from "react-i18next";

class RefillableFundsTableComponent extends ReduxViewTableComponent {
    constructor(props) {
        const columns = [
            {
                type: 'string',
                title: props.t('Title'),
                field: 'Title',
                total: false,
                sort: true,
            },
            {
                type: 'string',
                title: props.t('Description'),
                field: 'Description',
                total: false,
                sort: true,
            },
            {
                type: 'float',
                title: props.t('Target Amount'),
                field: 'TargetAmount',
                total: true,
                sort: true,
            },
            {
                type: 'date',
                title: props.t('Start Date'),
                field: 'StartDate',
                total: false,
                sort: true,
            },
            {
                type: 'int',
                title: props.t('Recovery Period Month'),
                field: 'RecoveryPeriodMonth',
                total: false,
                sort: true,
            },
            {
                type: 'float',
                title: props.t('Interest %'),
                field: 'Interest',
                total: false,
                sort: true,
            },
            {
                type: 'string',
                title: props.t('Currency'),
                field: 'Currency',
                total: false,
                sort: true,
            },
            {
                type: 'int',
                title: props.t('EmergencyFund'),
                //field: 'EmergencyFundId',
                field: 'EmergencyFund',
                total: false,
                sort: true,
            },
        ]
        super({...props, columns});
    }
}

const mapStateToProps = state => {
    let dd = []
    const emergencyFundFormatter = (value) => {
        let text = "-"
        if (value !== null) {
            text = "+"
            for (const type of state.emergency_funds.entities) {
                if (type.ID === value) {
                    text = type.Title
                    break
                }
            }
        }
        return text
    }
    const currencyFormatter = (value) => {
        let text = "-"
        if (value !== null) {
            text = "+"
            for (const type of state.currency.entities) {
                if (type.ID === value) {
                    text = type.Symbol
                    break
                } else {
                    text = value
                }
            }
        }
        return text
    }
    for (let dep of state.refillable_funds.entities) {
        dd.push({...dep, EmergencyFund: emergencyFundFormatter(dep.EmergencyFundID), Currency: currencyFormatter(dep.CurrencyID)})
    }
    return {
    data: dd,
    error: state.refillable_funds.error,
    isFetching: state.refillable_funds.loading,
    emergency_funds: state.emergency_funds.entities,
    currency: state.currency.entities,
}};

const mapDispatchToProps = dispatch => ({
    onReloadNeeded: () => dispatch(loadData()),
    onRowSelect: (row) => dispatch(select(row)),
    onDelete: (row) => dispatch(deleteRow(row)),
    onEdit: (row) => dispatch(updateRow(row)),
    onAdd: (row) => dispatch(addRow(row)),
});

RefillableFundsTableComponent = withTranslation()(connect(mapStateToProps, mapDispatchToProps)(RefillableFundsTableComponent));

const RefillableFundDeposits = createDepositsTableComponent({dataPath: 'refillable_fund_deposits', addRow: addDepositRow, updateRow: updateDepositRow, deleteRow: deleteDepositRow, loadData: loadDepositData})
const RefillableFundInterests = createInterestsTableComponent({dataPath: 'refillable_fund_interests', addRow: addInterestRow, updateRow: updateInterestRow, deleteRow: deleteInterestRow, loadData: loadInterestData})
const RefillableFundExpenses = createExpensesTableComponent({dataPath: 'refillable_fund_expenses', addRow: addExpensesRow, updateRow: updateExpensesRow, deleteRow: deleteExpensesRow, loadData: loadExpensesData})
const RefillableFundPaymentskip = createPaymentskipTableComponent({dataPath: 'refillable_fund_paymentskip', addRow: addPaymentskipRow, deleteRow: deletePaymentskipRow, loadData: loadPaymentskipData})

class RefillableFundsComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedRow: null,
        };
    }

    render() {
        return(
            <div style={{padding: "10px 10%", fontFamily: "Segoe ui"}}>
                <RefillableFundsTableComponent/>
                <h3>Deposits</h3>
                <RefillableFundDeposits />
                <h3>Expenses</h3>
                <RefillableFundExpenses />
                <h3>Interests</h3>
                <RefillableFundInterests />
                <h3>Paymentskip</h3>
                <RefillableFundPaymentskip />
                <h3>States</h3>
                {this.props.selectedRow != null ? (<RefillableFundStatesTableComponent parent_id={this.props.selectedRow}/>) : (<p>No refillable fund selected</p>)}
            </div>
        )
    }
}

const mapRefillableFundsToProps = state => ({
    selectedRow: state.refillable_funds.selected,
});

export default connect(mapRefillableFundsToProps)(RefillableFundsComponent);
