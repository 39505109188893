import ReduxViewTableComponent from "./redux.view.table.component";
import {connect} from "react-redux";
import {loadData, recount} from "../actions/retirement_fund_states"
import {withTranslation} from "react-i18next";

class RetirementFundStatesTableComponent extends ReduxViewTableComponent {
    constructor(props) {
        const columns = [
            {
                type: 'date',
                title: props.t('Date'),
                field: 'Date',
                total: false,
                sort: true,
            },
            {
                type: 'float',
                title: props.t('Month Payout + Inflation'),
                field: 'MonthPayoutAmountWithInflation',
                total: false,
                sort: true,
            },
            {
                type: 'float',
                title: props.t('Inflation %'),
                field: 'InflationPct',
                total: false,
                sort: true,
            },
            {
                type: 'float',
                title: props.t('Interest %'),
                field: 'InterestPct',
                total: false,
                sort: true,
            },
            {
                type: 'float',
                title: props.t('Start Amount'),
                field: 'StartAmount',
                total: false,
                sort: true,
            },
            {
                type: 'float',
                title: props.t('Deposit'),
                field: 'Deposit',
                total: true,
                sort: true,
            },
            {
                type: 'float',
                title: props.t('Interest'),
                field: 'Interest',
                total: true,
                sort: true,
            },
            {
                type: 'float',
                title: props.t('Expense'),
                field: 'Expense',
                total: true,
                sort: true,
            },
            {
                type: 'float',
                title: props.t('EndAmount'),
                field: 'EndAmount',
                total: false,
                sort: true,
            },
            {
                type: 'float',
                title: props.t('Planned Start Amount'),
                field: 'PlannedStartAmount',
                total: false,
                sort: true,
            },
            {
                type: 'float',
                title: props.t('Planned Deposit'),
                field: 'PlannedDeposit',
                total: true,
                sort: true,
            },
            {
                type: 'float',
                title: props.t('Planned Interest'),
                field: 'PlannedInterest',
                total: true,
                sort: true,
            },
            {
                type: 'float',
                title: props.t('Planned Expense'),
                field: 'PlannedExpense',
                total: true,
                sort: true,
            },
            {
                type: 'float',
                title: props.t('Planned End Amount'),
                field: 'PlannedEndAmount',
                total: false,
                sort: true,
            },
        ]
        super({...props, columns});
    }
}


const mapStateToProps = state => ({
    data: state.retirement_fund_states.entities,
    error: state.retirement_fund_states.error,
    isFetching: state.retirement_fund_states.loading,
});

const mapDispatchToProps = dispatch => ({
    onReloadNeeded: () => dispatch(loadData()),
    onRefresh: () => dispatch(recount()),
});

export default RetirementFundStatesTableComponent = withTranslation()(connect(mapStateToProps, mapDispatchToProps)(RetirementFundStatesTableComponent));
