import createDataSlice from "./data.slice.creater";
import {createAsyncThunk} from "@reduxjs/toolkit";
import AsyncFetchData from "./fetchData";
import {addMessage} from "./messages";
import {refreshToken} from "./auth";

const userinfoSlice = new createDataSlice({
    sliceName: 'userinfo',
    baseUrl: 'userinfo',
    loadOnAuth: true,
});

export const resendCode = createAsyncThunk(
    userinfoSlice.sliceName + '/resend_code/result',
    async (data, thunkAPI) => {
        thunkAPI.dispatch(userinfoSlice.loading())
        try {
            const response = await AsyncFetchData(thunkAPI.getState().auth, 'post', '/verify_mail/retry', null, null, null, data, () => {thunkAPI.dispatch(refreshToken())})
            thunkAPI.dispatch(addMessage('success', 'Verification code resent successfully'))
            return {
                data: response.data,
                error: null
            }
        } catch (err) {
            thunkAPI.dispatch(addMessage('danger', 'Error resending verification code'))
            return {
                data: [],
                error: err,
            }
        }
    }
)

export const verifyEmail = createAsyncThunk(
    userinfoSlice.sliceName + '/verify_email/result',
    async (data, thunkAPI) => {
        thunkAPI.dispatch(userinfoSlice.loading())
        try {
            const response = await AsyncFetchData(thunkAPI.getState().auth, 'post', '/verify_mail', null, null, null, data, () => {thunkAPI.dispatch(refreshToken())})
            thunkAPI.dispatch(addMessage('success', 'Email verified successfully'))
            thunkAPI.dispatch(loadData())
            return {
                data: response.data,
                error: null
            }
        } catch (err) {
            thunkAPI.dispatch(addMessage('danger', 'Error verifying email'))
            return {
                data: [],
                error: err,
            }
        }
    }
)

const {slice} = userinfoSlice;
const {actions, reducer} = slice;
export const {loadData} = userinfoSlice;

export default reducer;
