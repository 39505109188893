import createDataSlice from "./data.slice.creater";
import {select as selectRetirementFund} from "./retirement_funds";

const retirementFundPaymentskipSlice = new createDataSlice({
    sliceName: 'retirement_fund_paymentskip',
    baseUrl: 'retirement_funds/paymentskip',
    parentSelectedAction: selectRetirementFund,
    updatable: true,
    deletable: true,
    addable: true,
});

const {slice} = retirementFundPaymentskipSlice;
const {actions, reducer} = slice;
export const {addRow, deleteRow, loadData} = retirementFundPaymentskipSlice;

export default reducer;