import createDataSlice from "./data.slice.creater";
import {select as selectCredit} from "./credits";

const creditDepositsSlice = new createDataSlice({
    sliceName: 'credit_deposits',
    baseUrl: 'credits/deposit',
    parentSelectedAction: selectCredit,
    updatable: true,
    deletable: true,
    addable: true,
});

const {slice} = creditDepositsSlice;
const {actions, reducer} = slice;
export const {addRow, deleteRow, loadData, updateRow} = creditDepositsSlice;

export default reducer;