import createDataSlice from "./data.slice.creater";
import {select as selectEmergencyFund} from "./emergency_funds";

const emergencyFundExpensesSlice = new createDataSlice({
    sliceName: 'emergency_fund_expenses',
    baseUrl: 'emergency_funds/expense',
    parentSelectedAction: selectEmergencyFund,
    updatable: true,
    deletable: true,
    addable: true,
});

const {slice} = emergencyFundExpensesSlice;
const {actions, reducer} = slice;
export const {addRow, deleteRow, loadData, updateRow} = emergencyFundExpensesSlice;

export default reducer;