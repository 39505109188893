import React from "react";

import { LineChart, Line, CartesianGrid, XAxis, YAxis, Tooltip, ResponsiveContainer } from 'recharts';
import { connect } from "react-redux";

import { loadData as loadHistoryPerMonth } from "../actions/history_per_month";
import { withTranslation } from "react-i18next";

import strftime from "../strftime"

class HistoryComponent extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <div>
                <HistoryGraphComponent />
            </div>
        )
    }
}
export default HistoryComponent;

class HistoryGraphComponent extends React.Component {
    constructor(props) {
        super(props);
    }

    componentDidMount() {
        this.props.onReloadNeeded();
    }

    render() {
        return (
            <>
                {this.props.history_per_month.error != null ? (<p>Error on data request!</p>) : (this.props.history_per_month.loading ? (<p>Loading...</p>) : (
                    <div style={{ padding: "10px 5%", fontFamily: "Segoe ui" }}>
                        <ResponsiveContainer width="95%" height={700}>
                            <LineChart data={this.props.history_per_month.entities} syncId="anyId">
                                <Line type="monotone" dataKey="Deposit" name={this.props.t("Deposit")} stroke="#8ae6a4" strokeWidth={2} />
                                <Line type="monotone" dataKey="Interest" name={this.props.t("Interest")} stroke="#f5db14" strokeWidth={2} />
                                <Line type="monotone" dataKey="Expense" name={this.props.t("Expense")} stroke="#ff9382" strokeWidth={2} />
                                <CartesianGrid stroke="#ccc" />
                                <XAxis dataKey="Date" tickFormatter={(t) => strftime("%Y-%m-%d", t)} />
                                <YAxis domain={[0, 'dataMax']} />
                                <Tooltip />
                            </LineChart>
                        </ResponsiveContainer>
                    </div>
                ))
                }
            </>
        )
    }
}

const mapGraphStateToProps = state => ({
    history_per_month: state.history_per_month,
});

const mapGraphDispatchToProps = dispatch => ({
    onReloadNeeded: () => dispatch(loadHistoryPerMonth()),
});

HistoryGraphComponent = withTranslation()(connect(mapGraphStateToProps, mapGraphDispatchToProps)(HistoryGraphComponent));
