import React from "react";
import {withTranslation} from "react-i18next";
class DonateComponent extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <div style={{padding: "10px 10%", fontFamily: "Segoe ui"}}>
                <br/>
                {this.props.t("FinGoals is a free project, but you can support it with a")} &nbsp;
                <a className="donate-with-crypto"
                   href="https://commerce.coinbase.com/checkout/b53c0174-f009-4a73-813a-3406d7affef2">
                    {this.props.t("donation")}
                </a>
                . {this.props.t("Thank you!")}
            </div>
        );
    }
}

export default withTranslation()(DonateComponent);
