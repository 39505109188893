import React from "react";
import {ArrowRepeat} from "react-bootstrap-icons";
import {Button} from "react-bootstrap-buttons";
import ReduxViewTableComponent from "./redux.view.table.component";
import {connect} from "react-redux";

import {loadData as loadFiniteTargetStates, recount} from "../actions/finite_target_states";
import {withTranslation} from "react-i18next";

class FiniteTargetStatesTableComponent extends ReduxViewTableComponent {
    constructor(props) {
        const columns = [
            {
                type: 'date',
                title: props.t('Date'),
                field: 'Date',
                total: false,
                sort: true,
            },
            {
                type: 'float',
                title: props.t('Start Amount'),
                field: 'StartAmount',
                total: false,
                sort: true,
            },
            {
                type: 'float',
                title: props.t('Deposit'),
                field: 'Deposit',
                total: true,
                sort: true,
            },
            {
                type: 'float',
                title: props.t('Interest'),
                field: 'Interest',
                total: true,
                sort: true,
            },
            {
                type: 'float',
                title: props.t('End Amount'),
                field: 'EndAmount',
                total: false,
                sort: true,
            },
            {
                type: 'float',
                title: props.t('Planned Start Amount'),
                field: 'PlannedStartAmount',
                total: false,
                sort: true,
            },
            {
                type: 'float',
                title: props.t('Planned Deposit'),
                field: 'PlannedDeposit',
                total: true,
                sort: true,
            },
            {
                type: 'float',
                title: props.t('Planned Interest'),
                field: 'PlannedInterest',
                total: true,
                sort: true,
            },
            {
                type: 'float',
                title: props.t('Planned End Amount'),
                field: 'PlannedEndAmount',
                total: false,
                sort: true,
            },
        ]
        super({...props, columns});
    }
}

const mapStateToProps = state => ({
    data: state.finite_target_states.entities,
    error: state.finite_target_states.error,
    isFetching: state.finite_target_states.loading,
});

const mapDispatchToProps = dispatch => ({
    onReloadNeeded: () => dispatch(loadFiniteTargetStates()),
});

FiniteTargetStatesTableComponent = withTranslation()(connect(mapStateToProps, mapDispatchToProps)(FiniteTargetStatesTableComponent));

class FiniteTargetStatesComponent extends React.Component {
    constructor(props) {
        super(props);
    }
    render() {
        return(
            <div>
                <Button onClick={ this.props.recountState }><ArrowRepeat/></Button>
                <FiniteTargetStatesTableComponent/>
            </div>
        )
    }
}

const mapStatesDispatchToProps = dispatch => ({
    recountState: () => dispatch(recount()),
});

export default connect(null, mapStatesDispatchToProps)(FiniteTargetStatesComponent);
