import {createSlice} from "@reduxjs/toolkit";
//import incomesReloadNeededAction from "./incomes.reload_needed.action";
//import plannedIncomesReloadNeededAction from "./planned_incomes.reload_needed.action";
//import statesReloadNeededAction from "./states.reload_needed.action";
//import expensesReloadNeededAction from "./expenses.reload_needed.action";
//import incomeRecommendedDepositReloadNeededAction from "./income_recommended_deposit.reload_needed.action";

const today = new Date();
const year = today.getFullYear();
const month = today.getMonth() + 1;

const slice = createSlice({
    name: 'date',
    initialState: {
        year: year,
        month: month,
    },
    reducers: {
        changeDate: {
            reducer: (state, action) => {
                state.year = action.payload.year;
                state.month = action.payload.month;

                // TODO: move to other reducers
                //action.asyncDispatch(incomesReloadNeededAction())
                //action.asyncDispatch(plannedIncomesReloadNeededAction())
                //action.asyncDispatch(statesReloadNeededAction())
                //action.asyncDispatch(expensesReloadNeededAction())
                //action.asyncDispatch(incomeRecommendedDepositReloadNeededAction())
            },
            prepare: (year, month) => {
                return {
                    payload: {
                        year: year,
                        month: month,
                    }
                }
            },
        },
    },
})

const {actions, reducer} = slice;
export const {changeDate} = actions;

export default reducer;
