import createDataSlice from "./data.slice.creater";

import {select as selectPeriodicalTarget} from "./periodical_targets";

const periodicalTargetStatesSlice = new createDataSlice({
    sliceName: 'periodical_target_states',
    baseUrl: 'periodical_targets/states',
    parentSelectedAction: selectPeriodicalTarget,
    recountable: true,
});

const {slice} = periodicalTargetStatesSlice;
const {actions, reducer} = slice;
export const {loadData, recount} = periodicalTargetStatesSlice;

export default reducer;