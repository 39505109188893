import createDataSlice from "./data.slice.creater";

import {select as selectEmergencyFund} from "./retirement_funds";

const retirementFundStatesSlice = new createDataSlice({
    sliceName: 'retirement_fund_states',
    baseUrl: 'retirement_funds/states',
    parentSelectedAction: selectEmergencyFund,
    recountable: true,
});

const {slice} = retirementFundStatesSlice;
const {actions, reducer} = slice;
export const {loadData, recount} = retirementFundStatesSlice;

export default reducer;