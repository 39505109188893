import React from "react";
import CreditStatesComponent from './credit_states.component';
import ReduxViewTableComponent from "./redux.view.table.component";
import {createPaymentskipTableComponent} from './paymentskip.component';
import {connect} from "react-redux";

import {select, addRow, updateRow, deleteRow, loadData} from "../actions/credits";

import {createDepositsTableComponent} from "./deposits.component"
import {addRow as addDepositRow, updateRow as updateDepositRow, deleteRow as deleteDepositRow, loadData as loadDepositData} from "../actions/credit_deposits";
import {addRow as addPaymentskipRow, deleteRow as deletePaymentskipRow, loadData as loadPaymentskipData} from "../actions/credit_paymentskip";
import {withTranslation} from "react-i18next";

class CreditsTableComponent extends ReduxViewTableComponent {
    constructor(props) {
        const columns = [
            {
                type: 'string',
                title: props.t('Title'),
                field: 'Title',
                total: false,
                sort: true,
            },
            {
                type: 'string',
                title: props.t('Description'),
                field: 'Description',
                total: false,
                sort: true,
            },
            {
                type: 'float',
                title: props.t('Amount'),
                field: 'Amount',
                total: true,
                sort: true,
            },
            {
                type: 'date',
                title: props.t('Start Date'),
                field: 'StartDate',
                total: false,
                sort: true,
            },
            {
                type: 'int',
                title: props.t('Duration Month'),
                field: 'DurationMonth',
                total: false,
                sort: true,
            },
            {
                type: 'float',
                title: props.t('Interest %'),
                field: 'Interest',
                total: false,
                sort: true,
            },
            {
                type: 'int',
                title: props.t('Desired Duration Month'),
                field: 'DesiredDurationMonth',
                total: false,
                sort: true,
            },
            {
                type: 'date',
                title: props.t('Overpayment Start Date'),
                field: 'OverpaymentStartDate',
                total: false,
                sort: true,
            },
            {
                type: 'int',
                title: props.t('Pay Day of Month'),
                field: 'PayDay',
                total: false,
                sort: true,
            },
            {
                type: 'int',
                title: props.t('EmergencyFund'),
                //field: 'EmergencyFundId',
                field: 'EmergencyFund',
                total: false,
                sort: true,
            },
            {
                type: 'string',
                title: props.t('Currency'),
                field: 'Currency',
                total: false,
                sort: true,
            },
        ]
        super({...props, columns});
    }
}

const mapStateToProps = state => {
    let dd = []
    const emergencyFundFormatter = (value) => {
        let text = "-"
        if (value !== null) {
            text = "+"
            for (const type of state.emergency_funds.entities) {
                if (type.ID === value) {
                    text = type.Title
                    break
                }
            }
        }
        return text
    }
    const currencyFormatter = (value) => {
        let text = "-"
        if (value !== null) {
            text = "+"
            for (const type of state.currency.entities) {
                if (type.ID === value) {
                    text = type.Symbol
                    break
                } else {
                    text = value
                }
            }
        }
        return text
    }
    for (let dep of state.credits.entities) {
        dd.push({...dep, EmergencyFund: emergencyFundFormatter(dep.EmergencyFundID), Currency: currencyFormatter(dep.CurrencyID)})
    }
    return {
        data: dd,
        error: state.credits.error,
        isFetching: state.credits.loading,
        emergency_funds: state.emergency_funds.entities,
        currency: state.currency.entities,
    }
};

const mapDispatchToProps = dispatch => ({
    onReloadNeeded: () => dispatch(loadData()),
    onRowSelect: (row) => dispatch(select(row)),
    onDelete: (row) => dispatch(deleteRow(row)),
    onEdit: (row) => dispatch(updateRow(row)),
    onAdd: (row) => dispatch(addRow(row)),
});

CreditsTableComponent = withTranslation()(connect(mapStateToProps, mapDispatchToProps)(CreditsTableComponent));

const CreditDeposits = createDepositsTableComponent({dataPath: 'credit_deposits', addRow: addDepositRow, updateRow: updateDepositRow, deleteRow: deleteDepositRow, loadData: loadDepositData})
const CreditPaymentskip = createPaymentskipTableComponent({dataPath: 'credit_paymentskip', addRow: addPaymentskipRow, deleteRow: deletePaymentskipRow, loadData: loadPaymentskipData})

class CreditsComponent extends React.Component {
    render() {
        return(
            <div style={{padding: "10px 10%", fontFamily: "Segoe ui"}}>
                <CreditsTableComponent />
                <h3>Deposits</h3>
                <CreditDeposits />
                <h3>Paymentskip</h3>
                <CreditPaymentskip />
                <h3>States</h3>
                {this.props.selectedRow != null ? (<CreditStatesComponent />) : (<p>No credit selected</p>)}
            </div>
        )
    }
}

//                 {this.props.selectedRow != null ? (<DepositsComponent type='credits' parent_id={this.props.selectedRow}/>) : (<p>No credit selected</p>)}

const mapCreditsStateToProps = state => ({
    selectedRow: state.credits.selected,
});

export default connect(mapCreditsStateToProps)(CreditsComponent);
