import {Link} from "react-router-dom";
import React from "react";

export default function Menu(props) {
    const routes = props.routes

    if (routes === undefined || routes === null) {
        return null
    }

    return (
        <ul className={"menu"}>
            {routes.filter((route) => (route.showInMenu !== false)).map((route, index) => (
                <li>
                    <Link key={index} to={route.path}>{route.title}</Link>
                </li>
            ))}
        </ul>
    );
}
