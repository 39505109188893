import {addMessage} from "./messages";
import AsyncFetchData from "./fetchData";
import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";

import {changeDate} from "./date";
import {login, refreshToken} from "./auth";

/* props:
*   sliceName
*   baseUrl
*   parentSelectedAction
*   updatable
*   deletable
*   addable
 */
export default function createDataSlice (props) {
    return new DataSlice(props);
}

class DataSlice {
    constructor(props) {
        this.sliceName = props.sliceName;
        this.baseUrl = props.baseUrl;
        this.initialState = { entities: [], error: null, loading: false, selected: null, parent_id: null };
        this.parentSelectedAction = props.parentSelectedAction; // parentSelectedAction: blah.actions.select for reducers or asyncAction.fulfilled for asyncReducer!!

        this.updatable = props.updatable;
        this.deletable = props.deletable;
        this.addable = props.addable;
        this.recountable = props.recountable;
        this.useParentIdOnRecount = props.useParentIdOnRecount;

        this.reloadOnDateChanged = props.reloadOnDateChanged // true/false, adds extra reducer for date changed & date in the end of request url
        this.loadOnAuth = props.loadOnAuth // true/false, adds extra reducer for auth complete

        this.loading = () => {};

        if (props.customAddFunction !== undefined) {
            this.customAddFunction = props.customAddFunction
            this.addable = true
        }
        if (props.customEditFunction !== undefined) {
            this.customEditFunction = props.customEditFunction
            this.updatable = true
        }
        if (props.customDeleteFunction !== undefined) {
            this.customDeleteFunction = props.customDeleteFunction
            this.deletable = true
        }

        this.reloadDependency = props.reloadDependency
// C add
//    started
//    success
//    failed
        if (this.addable === true) {
            this.addRow = createAsyncThunk(
                this.sliceName + '/add/result',
                async (row, thunkAPI) => {
                    thunkAPI.dispatch(this.loading())
                    if (this.customAddFunction !== undefined) {
                        return this.customAddFunction(row, thunkAPI)
                    } else {
                        const parent_id = thunkAPI.getState()[this.sliceName].parent_id
                        try {
                            const response = await AsyncFetchData(thunkAPI.getState().auth, 'post', this.baseUrl, null, null, parent_id, row, () => {thunkAPI.dispatch(refreshToken())})
                            thunkAPI.dispatch(addMessage('success', this.sliceName + ' added successfully'))
                            return {
                                data: response.data,
                                error: null
                            }
                        } catch (err) {
                          if (err._retry !== true) {
                            thunkAPI.dispatch(addMessage('danger', 'Error adding ' + this.sliceName))
                          }
                            return {
                                data: [],
                                error: err,
                            }
                        }
                    }
                }
            )
        }
// R load
//    started
//    success
//    failed
        this.loadData = createAsyncThunk(
            this.sliceName + '/load/result',
            async (row, thunkAPI) => {
                thunkAPI.dispatch(this.loading())
                const parent_id = thunkAPI.getState()[this.sliceName].parent_id

                if (this.parentSelectedAction !== null && this.parentSelectedAction !== undefined && parent_id == null) {
                    console.log("We got parentSelectedAction but no parent_id, looks like parent not selected")
                    return {
                        data: [],
                        err: null,
                    }
                }

                try {
                    let date = null
                    if (this.reloadOnDateChanged === true) {
                        date = thunkAPI.getState().date
                    }
                    const response = await AsyncFetchData(thunkAPI.getState().auth, 'get', this.baseUrl, null, date, parent_id, null, () => {thunkAPI.dispatch(refreshToken())})
                    return {
                        data: response.data,
                        error: null
                    }
                } catch (err) {
                  console.log("Load error: ", err)
                  if (err._retry !== true) {
                    thunkAPI.dispatch(addMessage('danger', 'Error loading ' + this.sliceName))
                  }
                    return {
                        data: [],
                        error: err,
                    }
                }
            }
        )
// U update
//    started
//    success
//    failed
        if (this.updatable === true) {
            this.updateRow = createAsyncThunk(
                this.sliceName + '/update/result',
                async (row, thunkAPI) => {
                    thunkAPI.dispatch(this.loading())
                    if (this.customEditFunction !== undefined) {
                        return this.customEditFunction(row, thunkAPI)
                    } else {
                        const parent_id = thunkAPI.getState()[this.sliceName].parent_id
                        try {
                            const response = await AsyncFetchData(thunkAPI.getState().auth, 'put', this.baseUrl, null, null, parent_id, row, () => {thunkAPI.dispatch(refreshToken())})
                            thunkAPI.dispatch(addMessage('success', this.sliceName + ' updated successfully'))
                            return {
                                data: response.data,
                                error: null
                            }
                        } catch (err) {
                          if (err._retry !== true) {
                            thunkAPI.dispatch(addMessage('danger', 'Error updating ' + this.sliceName))
                          }
                            return {
                                data: [],
                                error: err,
                            }
                        }
                    }
                }
            )
        }
// D delete
//    started
//    success
//    failed
        if (this.deletable === true) {
            this.deleteRow = createAsyncThunk(
                this.sliceName + '/delete/result',
                async (row, thunkAPI) => {
                    thunkAPI.dispatch(this.loading())
                    if (this.customDeleteFunction !== undefined) {
                        return this.customDeleteFunction(row, thunkAPI)
                    } else {
                        const ID = row.ID
                        try {
                            await AsyncFetchData(thunkAPI.getState().auth, 'delete', this.baseUrl + '/' + ID, null, null, null, () => {thunkAPI.dispatch(refreshToken())})
                            thunkAPI.dispatch(addMessage('success', this.sliceName + ' deleted successfully'))
                            return {
                                error: null
                            }
                        } catch (err) {
                          if (err._retry !== true) {
                            thunkAPI.dispatch(addMessage('danger', 'Error deleting ' + this.sliceName))
                          }
                            return {
                                error: err,
                            }
                        }
                    }
                }
            )
        }
// recount
        if (this.recountable === true) {
            this.recount = createAsyncThunk(
                this.sliceName + '/recount/result',
                async (_, thunkAPI) => {
                    thunkAPI.dispatch(this.loading())
                    let ID = null
                    if (this.useParentIdOnRecount === true) {
                        ID = thunkAPI.getState()[this.sliceName].parent_id
                    }
                    try {
                        //FetchData(getState().auth,'post', resultCallback, 'incomes/recommended_deposit', null, null, income_id)
                        await AsyncFetchData(thunkAPI.getState().auth, 'post', this.baseUrl, null, null, ID, () => {thunkAPI.dispatch(refreshToken())})
                        thunkAPI.dispatch(addMessage('success', this.sliceName + ' recounted successfully'))
                        return {
                            error: null
                        }
                    } catch (err) {
                      if (err._retry !== true) {
                        thunkAPI.dispatch(addMessage('danger', 'Error recounting ' + this.sliceName))
                      }
                        return {
                            error: err,
                        }
                    }
                })
        }
        //

        const sliceOptions = {
            name: this.sliceName,
            initialState: this.initialState,
            reducers: {
                loading(state, action) {
                    state.loading = true
                },
                select: {
                    reducer: (state, action) => {
                        state.selected = action.payload.selected
                    },
                    prepare: (row) => {
                        return {
                            payload: {
                                selected: row.ID
                            }
                        }
                    }
                },
            },
            extraReducers: {
                [this.loadData.fulfilled]: (state, action) => {
                    state.entities = action.payload.data
                    state.error = action.payload.error
                    state.loading = false
                },
            },
        }

        if (this.updatable === true) {
            sliceOptions.extraReducers[this.updateRow.fulfilled] = (state, action) => {
                //state.entities = action.payload.data
                //state.error = action.payload.error
                state.loading = false
                action.asyncDispatch(this.loadData())
            };
        }
        if (this.deletable === true) {
            sliceOptions.extraReducers[this.deleteRow.fulfilled] = (state, action) => {
                //state.entities = action.payload.data
                //state.error = action.payload.error
                state.loading = false
                action.asyncDispatch(this.loadData())
            };
        }
        if (this.addable === true) {
            sliceOptions.extraReducers[this.addRow.fulfilled] = (state, action) => {
                //state.entities = action.payload.data
                //state.error = action.payload.error
                state.loading = false
                action.asyncDispatch(this.loadData())
            };
        }
        if (this.recountable === true) {
            sliceOptions.extraReducers[this.recount.fulfilled] = (state, action) => {
                //state.entities = action.payload.data
                //state.error = action.payload.error
                state.loading = false
                action.asyncDispatch(this.loadData())
            };
        }

        if (this.parentSelectedAction !== null && this.parentSelectedAction !== undefined) {
            console.log("Adding parentSelectedAction to slice " + this.sliceName)
            // add parent id & parent selected action
            sliceOptions.extraReducers[this.parentSelectedAction] = (state, action) => {
                console.log("Parent selected action executed", state, action)
                state.parent_id = action.payload.selected
                action.asyncDispatch(this.loadData())
            };
        } else {
            console.log("There is not parentSelectedAction for slice " + this.sliceName + ": ", this.parentSelectedAction)
        }

        if (this.reloadOnDateChanged === true) {
            console.log("Adding reload on date changed reducer to slice " + this.sliceName)
            sliceOptions.extraReducers[changeDate] = (state, action) => {
                console.log("Reload on date change action executed", state, action)
                state.date = action.payload
                action.asyncDispatch(this.loadData())
            };
        }

        if (this.loadOnAuth === true) {
            console.log("Adding load on auth reducer to slice " + this.sliceName)
            sliceOptions.extraReducers[login.fulfilled] = (state, action) => {
                console.log("Load on auth action executed", state, action)
                if (action.payload.loggedIn === true) {
                    action.asyncDispatch(this.loadData())
                } else {
                    console.log("Wrong state of loggedIn flag in payload")
                }
            };
        }

        if (this.reloadDependency !== undefined && Array.isArray(this.reloadDependency) === true) {
            console.log("Adding custom dependent actions")
            for (let dep of this.reloadDependency) {
                sliceOptions.extraReducers[dep.fulfilled] = (state, action) => {
                    console.log("Load on custom dependent action executed", state, action)
                    action.asyncDispatch(this.loadData())
                }
            }
        }

        console.log("Creating slice " + this.sliceName + " with config ", sliceOptions)

        this.slice = createSlice(sliceOptions)

        this.loading = this.slice.actions.loading;
    }
}
